<template>
  <GeneralLayout>
    <div class="flex flex-col">
      <div class="flex-row my-4 items-stretch">
        <h1 class="text-3xl font-bold float-start">Analytics</h1>
      </div>
    </div>
  </GeneralLayout>
</template>
  
<script>
import GeneralLayout from '@/layouts/GeneralLayout'
  
export default {
  components: {
    GeneralLayout,
  },

  data() {
    
  },
  async mounted() {
    
  },

  methods: {
    
  },
  computed: {
    
  }
  
};
</script>
  