<template>
  <GeneralLayout>
    <div class="flex flex-col">
      <div class="flex-row my-4 items-stretch">
        <h1 class="text-3xl font-bold float-start">Accounts</h1>
      </div>
      <div v-if="message" class="float-start flex-row rounded m-2" :class="messageClass">
        <p>{{ message }}</p>
      </div>
      <div class="flex-row my-4 items-stretch">
        <button @click="showCloud" class="float-start px-3 py-1 border rounded hover:bg-gray-200" :class="cloud_class">Cloud</button>
        <button @click="showRepo" class="float-start px-3 py-1 ml-1 border rounded hover:bg-gray-200" :class="repo_class">Repository</button>
        <button @click="showDns" class="float-start px-3 py-1 ml-1 border rounded hover:bg-gray-200" :class="dns_class">DNS</button>
        <div v-if="show_cloud">
          <button @click="showNewCloudAccountPopup" class="bg-fuchsia-700 hover:bg-fuchsia-800 rounded py-1 px-4 text-white font-medium float-end mx-1 border border-white" type="button">New Cloud Account</button>
          <input v-model="search_connection" type="text" class="border-solid border-gray-200 border rounded-md shadow-sm px-2 py-1 mx-1 float-end " />
        </div>
        <div v-if="show_repo">
          <button @click="showNewRepositoryPopup" class="bg-fuchsia-700 hover:bg-fuchsia-800 rounded py-1 px-4 text-white font-medium float-end mx-1 border border-white" type="button">New Repo Account</button>
          <input v-model="search_repository" type="text" class="border-solid border-gray-200 border rounded-md shadow-sm px-2 py-1 mx-1 float-end " />
        </div>
        <div v-if="show_dns">
          <button @click="showNewDnsPopup" class="bg-fuchsia-700 hover:bg-fuchsia-800 rounded py-1 px-4 text-white font-medium float-end mx-1 border border-white" type="button">New Dns Account</button>
          <input v-model="search_connection" type="text" class="border-solid border-gray-200 border rounded-md shadow-sm px-2 py-1 mx-1 float-end " />
        </div>
      </div>
      <!-- Cloud -->
      <div v-if="show_cloud" class="overflow-x-auto">
        <table class="min-w-full bg-white border border-gray-200">
            <thead>
                <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                    <th class="py-3 px-6 text-center">Name</th>
                    <th class="py-3 px-6 text-center">Provider</th>
                    <th class="py-3 px-6 text-center">Connected</th>
                    <th class="py-3 px-6 text-center">Last Connection</th>
                    <th class="py-3 px-6 text-center">Actions</th>
                </tr>
            </thead>
            <tbody class="text-gray-600 text-sm font-light min-w-full">
                <tr v-for="cloud in filteredCloudAccount" :key="cloud.id" class="border-b border-gray-200">
                    <td class="py-3 px-6 text-center ">{{cloud.name}}</td>
                    <td class="py-3 px-6 text-center">{{ cloud.provider }}</td>
                    <td v-if="cloud.connected" class="py-3 px-6 text-center text-green-500">{{ cloud.connected }}</td>
                    <td v-else class="py-3 px-6 text-center text-red-500">{{ cloud.connected }}</td>
                    <td class="py-3 px-6 text-center">{{ cloud.last_connection }}</td>
                    <td class="py-3 px-6 text-center">
                        <button @click="showEditCloudAccountPopup(cloud)" class="bg-white text-fuchsia-600 py-1 px-3 rounded hover:bg-gray-200 font-medium mr-2 border-solid border border-fuchsia-600">Edit</button>
                        <button @click="showDeleteCloudAccountPopup(cloud.id)" class="bg-fuchsia-700 text-white  py-1 px-3 rounded hover:bg-fuchsia-800 font-medium">Delete</button>
                    </td>
                </tr>
            </tbody>
        </table>
      </div>
      <PopupForm ref="newCloudAccountForm" title="New Cloud Account" @form-submitted="createNewCloudAccount">
        <div class="mb-4">
          <label for="name" class="block text-sm font-medium text-gray-700 float-start">Name</label>
            <input id="name" v-model="create_cloud_form.name" type="text" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
        <div class="mb-4">
          <label for="provider" class="block text-sm font-medium text-gray-700 float-start">Provider</label>
          <select id="provider" v-model="create_cloud_form.provider" required class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2">
            <option v-for="provider in cloud_providers" :key="provider" :label="provider" :value="provider">{{provider}}</option>
          </select>
        </div>
        <div class="mb-4">
          <label for="api_key" class="block text-sm font-medium text-gray-700 float-start">Api Key</label>
          <input id="api_key" v-model="create_cloud_form.api_key" type="password" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
      </PopupForm>
      <PopupForm ref="editCloudAccountForm" title="Edit Cloud Account" @form-submitted="editCloudAccount(edit_cloud_form.id)">
        <div class="mb-4">
          <label for="name" class="block text-sm font-medium text-gray-700 float-start">Name</label>
            <input id="name" v-model="edit_cloud_form.name" type="text" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
        <div class="mb-4">
          <label for="provider" class="block text-sm font-medium text-gray-700 float-start">Provider</label>
          <select id="provider" v-model="edit_cloud_form.provider" required class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2">
            <option v-for="provider in cloud_providers" :key="provider" :label="provider" :value="provider">{{provider}}</option>
          </select>
        </div>
        <div class="mb-4">
          <label for="api_key" class="block text-sm font-medium text-gray-700 float-start">Api Key</label>
          <input id="api_key" v-model="edit_cloud_form.api_key" type="password" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
      </PopupForm>
      <PopupForm ref="deleteCloudAccountForm" title="Delete Cloud Account" @form-submitted="delCloudAccount(cloud_delete_id)">
        <div class="mb-4">
          <p class="block text-sm font-medium text-gray-700 float-start">Are you sure do you want to delete it?</p>
        </div>
      </PopupForm>

      <!-- Repo -->
      <div v-if="show_repo" class="overflow-x-auto">
        <table class="min-w-full bg-white border border-gray-200">
            <thead>
                <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                    <th class="py-3 px-6 text-center">Name</th>
                    <th class="py-3 px-6 text-center">Provider</th>
                    <th class="py-3 px-6 text-center">Connected</th>
                    <th class="py-3 px-6 text-center">Last Connection</th>
                    <th class="py-3 px-6 text-center">Actions</th>

                </tr>
            </thead>
            <tbody class="text-gray-600 text-sm font-light min-w-full">
                <tr v-for="repo in filteredRepoAccount" :key="repo.id" class="border-b border-gray-200">
                    <td class="py-3 px-6 text-center ">{{repo.name}}</td>
                    <td class="py-3 px-6 text-center">{{ repo.provider }}</td>
                    <td v-if="repo.connected" class="py-3 px-6 text-center text-green-500">{{ repo.connected }}</td>
                    <td v-else class="py-3 px-6 text-center text-red-500">{{ repo.connected }}</td>
                    <td class="py-3 px-6 text-center">{{ repo.last_connection }}</td>
                    <td class="py-3 px-6 text-center">
                        <button @click="showEditRepositoryPopup(repo)" class="bg-white text-fuchsia-600 py-1 px-3 rounded hover:bg-gray-200 font-medium mr-2 border-solid border border-fuchsia-600">Edit</button>
                        <button @click="showDeleteRepositoryPopup(repo.id)" class="bg-fuchsia-700 text-white  py-1 px-3 rounded hover:bg-fuchsia-800 font-medium">Delete</button>
                    </td>
                </tr>
            </tbody>
        </table>
      </div>
      <PopupForm ref="newRepositoryForm" title="New Repository" @form-submitted="createNewRepository">
        <div class="mb-4">
          <label for="name" class="block text-sm font-medium text-gray-700 float-start">Name</label>
            <input id="name" v-model="create_repo_form.name" type="text" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
        <div class="mb-4">
          <label for="provider" class="block text-sm font-medium text-gray-700 float-start">Provider</label>
          <select id="provider" v-model="create_repo_form.provider" required class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2">
            <option v-for="provider in repo_providers" :key="provider" :label="provider" :value="provider">{{provider}}</option>
          </select>
        </div>
        <div class="mb-4">
          <label for="api_key" class="block text-sm font-medium text-gray-700 float-start">Api Key</label>
          <input id="api_key" v-model="create_repo_form.api_key" type="password" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
      </PopupForm>
      <PopupForm ref="editRepositoryForm" title="Edit Repository" @form-submitted="editRepository(edit_repo_form.id)">
        <div class="mb-4">
          <label for="name" class="block text-sm font-medium text-gray-700 float-start">Name</label>
            <input id="name" v-model="edit_repo_form.name" type="text" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
        <div class="mb-4">
          <label for="provider" class="block text-sm font-medium text-gray-700 float-start">Provider</label>
          <select id="provider" v-model="edit_repo_form.provider" required class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2">
            <option v-for="provider in repo_providers" :key="provider" :label="provider" :value="provider">{{provider}}</option>
          </select>
        </div>
        <div class="mb-4">
          <label for="api_key" class="block text-sm font-medium text-gray-700 float-start">Api Key</label>
          <input id="api_key" v-model="edit_repo_form.api_key" type="password" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
      </PopupForm>
      <PopupForm ref="deleteRepositoryForm" title="Delete Repository" @form-submitted="delRepository(repo_delete_id)">
        <div class="mb-4">
          <p class="block text-sm font-medium text-gray-700 float-start">Are you sure do you want to delete it?</p>
        </div>
      </PopupForm>

      <!-- Dns -->
      <div v-if="show_dns" class="overflow-x-auto">
        <table class="min-w-full bg-white border border-gray-200">
            <thead>
                <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                    <th class="py-3 px-6 text-center">Name</th>
                    <th class="py-3 px-6 text-center">Provider</th>
                    <th class="py-3 px-6 text-center">Connected</th>
                    <th class="py-3 px-6 text-center">Last Connection</th>
                    <th class="py-3 px-6 text-center">Actions</th>
                </tr>
            </thead>
            <tbody class="text-gray-600 text-sm font-light min-w-full">
                <tr v-for="dns in filteredDnsAccount" :key="dns.id" class="border-b border-gray-200">
                    <td class="py-3 px-6 text-center ">{{dns.name}}</td>
                    <td class="py-3 px-6 text-center">{{ dns.provider }}</td>
                    <td v-if="dns.connected" class="py-3 px-6 text-center text-green-500">{{ dns.connected }}</td>
                    <td v-else class="py-3 px-6 text-center text-red-500">{{ dns.connected }}</td>
                    <td class="py-3 px-6 text-center">{{ dns.last_connection }}</td>
                    <td class="py-3 px-6 text-center">
                        <button @click="showEditDnsPopup(dns)" class="bg-white text-fuchsia-600 py-1 px-3 rounded hover:bg-gray-200 font-medium mr-2 border-solid border border-fuchsia-600">Edit</button>
                        <button @click="showDeleteDnsPopup(dns.id)" class="bg-fuchsia-700 text-white  py-1 px-3 rounded hover:bg-fuchsia-800 font-medium">Delete</button>
                    </td>
                </tr>
            </tbody>
        </table>
      </div>
      <PopupForm ref="newDnsForm" title="New Dns" @form-submitted="createNewDns">
        <div class="mb-4">
          <label for="name" class="block text-sm font-medium text-gray-700 float-start">Name</label>
            <input id="name" v-model="create_dns_form.name" type="text" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
        <div class="mb-4">
          <label for="provider" class="block text-sm font-medium text-gray-700 float-start">Provider</label>
          <select id="provider" v-model="create_dns_form.provider" required class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2">
            <option v-for="provider in dns_providers" :key="provider" :label="provider" :value="provider">{{provider}}</option>
          </select>
        </div>
        <div class="mb-4">
          <label for="api_key" class="block text-sm font-medium text-gray-700 float-start">Api Key</label>
          <input id="api_key" v-model="create_dns_form.api_key" type="password" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
      </PopupForm>
      <PopupForm ref="editDnsForm" title="Edit Dns" @form-submitted="editDns(edit_dns_form.id)">
        <div class="mb-4">
          <label for="name" class="block text-sm font-medium text-gray-700 float-start">Name</label>
            <input id="name" v-model="edit_dns_form.name" type="text" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
        <div class="mb-4">
          <label for="provider" class="block text-sm font-medium text-gray-700 float-start">Provider</label>
          <select id="provider" v-model="edit_dns_form.provider" required class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2">
            <option v-for="provider in dns_providers" :key="provider" :label="provider" :value="provider">{{provider}}</option>
          </select>
        </div>
        <div class="mb-4">
          <label for="api_key" class="block text-sm font-medium text-gray-700 float-start">Api Key</label>
          <input id="api_key" v-model="edit_dns_form.api_key" type="password" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
      </PopupForm>
      <PopupForm ref="deleteDnsForm" title="Delete Dns" @form-submitted="delDns(dns_delete_id)">
        <div class="mb-4">
          <p class="block text-sm font-medium text-gray-700 float-start">Are you sure do you want to delete it?</p>
        </div>
      </PopupForm>
      
    </div>
  </GeneralLayout>
</template>
  
<script>
import GeneralLayout from '@/layouts/GeneralLayout'
import PopupForm from '@/components/PopupForm.vue';
import { 
  // Cloud
  getCloudAccounts, createCloudAccount, updateCloudAccount, deleteCloudAccount,
  // Repos
  getRepositoryAccounts, createRepositoryAccount, updateRepositoryAccount, deleteRepositoryAccount,
  // DNS
  getDnsAccounts, createDnsAccount, updateDnsAccount, deleteDnsAccount,
} from '../services/api';
  
export default {
  components: {
    GeneralLayout,
    PopupForm,
  },

  data() {
    return {
      // Common
      message: '',
      messageClass: '',
      // Cloud
      create_cloud_form: {
        name: '',
        provider: '',
        api_key: '',
      },
      edit_cloud_form: {
        id: '',
        name: '',
        provider: '',
        api_key: '',
      },
      cloud_accounts: [],
      cloud_search: '',
      cloud_delete_id: '',
      show_cloud: true,
      cloud_class: 'bg-fuchsia-700 text-white',
      cloud_providers: [
        'hetzner'
      ],

      // Repo
      create_repo_form: {
        name: '',
        provider: '',
        api_key: '',
      },
      edit_repo_form: {
        id: '',
        name: '',
        provider: '',
        api_key: '',
      },
      repo_accounts: [],
      repo_search: '',
      repo_delete_id: '',
      show_repo: false,
      repo_class: '',
      repo_providers: [
        'github'
      ],

      // Dns
      create_dns_form: {
        name: '',
        provider: '',
        api_key: '',
      },
      edit_dns_form: {
        id: '',
        name: '',
        provider: '',
        api_key: '',
      },
      dns_accounts: [],
      dns_search: '',
      dns_delete_id: '',
      show_dns: false,
      dns_class: '',
      dns_providers: [
        'ionos',
        'cdmon'
      ],
    };
  },
  async mounted() {
    try {
      this.cloud_accounts = await getCloudAccounts().then(res => res.data);
      this.repo_accounts = await getRepositoryAccounts().then(res => res.data);
      this.dns_accounts = await getDnsAccounts().then(res => res.data);
    }
    catch {
      localStorage.removeItem('token');
      this.$router.push('/login');
    }
  },

  methods: {
    // Cloud 
    showCloud(){
      this.show_cloud=true
      this.show_repo=false
      this.show_dns=false
      this.cloud_class='bg-fuchsia-700 text-white'
      this.repo_class=''
      this.dns_class=''
    },
    showNewCloudAccountPopup() {
      this.$refs.newCloudAccountForm.openForm();
    },
    showDeleteCloudAccountPopup(id) {
      this.cloud_delete_id = id
      this.$refs.deleteCloudAccountForm.openForm();
    },
    showEditCloudAccountPopup(cloud_account) {
      this.edit_cloud_form.id = cloud_account.id
      this.edit_cloud_form.name = cloud_account.name
      this.edit_cloud_form.provider = cloud_account.provider
      this.edit_cloud_form.api_key = cloud_account.api_key

      this.$refs.editCloudAccountForm.openForm();
    },
    async createNewCloudAccount() {
      try{
        const response = await createCloudAccount(this.create_cloud_form).then(res => res.data);
        if (response && response.message.includes('created successfully')) {
          
          this.cloud_accounts = await getCloudAccounts().then(res => res.data);
          this.messageClass = 'bg-green-500'
          this.message = 'Cloud Account created successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't create the cloud account"
          setTimeout(() => this.message = '', 2000);
        }
        this.create_cloud_form = {
          name: '',
          provider: '',
          api_key: '',
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't create the cloud account"
        setTimeout(() => this.message = '', 2000);
      }
    },
    async editCloudAccount(id) {
      try{
        const response = await updateCloudAccount(id, this.edit_cloud_form).then(res => res.data);
        if (response && response.message.includes('edited successfully')) {
          this.cloud_accounts = await getCloudAccounts().then(res => res.data);
          this.messageClass = 'bg-green-500'
          this.message = 'Cloud account edited successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't edit the cloud account"
          setTimeout(() => this.message = '', 2000);
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't edit the cloud account"
        setTimeout(() => this.message = '', 2000);
      }
    },
    async delCloudAccount(id) {
      try{
        const response = await deleteCloudAccount(id).then(res => res.data);
        if (response && response.message.includes('deleted successfully')) {
          this.edit_cloud_form = {
            id: '',
            name: '',
            provider: '',
            api_key: '',
          }
          this.cloud_accounts = await getCloudAccounts().then(res => res.data);
          this.messageClass = 'bg-green-500'
          this.message = 'Cloud account deleted successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't delete the cloud account"
          setTimeout(() => this.message = '', 2000);
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't delete the cloud account"
        setTimeout(() => this.message = '', 2000);
      }
    },

    // Repos
    showRepo(){
      this.show_cloud=false
      this.show_repo=true
      this.show_dns=false
      this.cloud_class=''
      this.repo_class='bg-fuchsia-700 text-white'
      this.dns_class=''
    },
    showNewRepositoryPopup() {
      this.$refs.newRepositoryForm.openForm();
    },
    showDeleteRepositoryPopup(id) {
      this.repo_delete_id = id
      this.$refs.deleteRepositoryForm.openForm();
    },
    showEditRepositoryPopup(repository) {
      this.edit_repo_form.id = repository.id
      this.edit_repo_form.name = repository.name
      this.edit_repo_form.provider = repository.provider
      this.edit_repo_form.api_key = repository.api_key

      this.$refs.editRepositoryForm.openForm();
    },
    async createNewRepository() {
      try{
        const response = await createRepositoryAccount(this.create_repo_form).then(res => res.data);
        if (response && response.message.includes('created successfully')) {
          
          this.repo_accounts = await getRepositoryAccounts().then(res => res.data);
          this.messageClass = 'bg-green-500'
          this.message = 'Repository account created successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't create the repository account"
          setTimeout(() => this.message = '', 2000);
        }
        this.create_repo_form = {
          name: '',
          provider: '',
          api_key: '',
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't create the repository account"
        setTimeout(() => this.message = '', 2000);
      }
    },
    async editRepository(id) {
      try{
        const response = await updateRepositoryAccount(id, this.edit_repo_form).then(res => res.data);
        if (response && response.message.includes('edited successfully')) {
          this.repo_accounts = await getRepositoryAccounts().then(res => res.data);
          this.messageClass = 'bg-green-500'
          this.message = 'Repository edited successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't edit the repository account"
          setTimeout(() => this.message = '', 2000);
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't edit the repository account"
        setTimeout(() => this.message = '', 2000);
      }
    },
    async delRepository(id) {
      try{
        const response = await deleteRepositoryAccount(id).then(res => res.data);
        if (response && response.message.includes('deleted successfully')) {
          this.edit_form = {
            id: '',
            name: '',
            provider: '',
            api_key: '',
          }
          this.repo_accounts = await getRepositoryAccounts().then(res => res.data);
          this.messageClass = 'bg-green-500'
          this.message = 'Repository account deleted successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't delete the repository account"
          setTimeout(() => this.message = '', 2000);
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't delete the repository account"
        setTimeout(() => this.message = '', 2000);
      }
    },

    // Dns
    showDns(){
      this.show_cloud=false
      this.show_repo=false
      this.show_dns=true
      this.cloud_class=''
      this.repo_class=''
      this.dns_class='bg-fuchsia-700 text-white'
    },
    showNewDnsPopup() {
      this.$refs.newDnsForm.openForm();
    },
    showDeleteDnsPopup(id) {
      this.dns_delete_id = id
      this.$refs.deleteDnsForm.openForm();
    },
    showEditDnsPopup(repository) {
      this.edit_dns_form.id = repository.id
      this.edit_dns_form.name = repository.name
      this.edit_dns_form.provider = repository.provider
      this.edit_dns_form.api_key = repository.api_key

      this.$refs.editDnsForm.openForm();
    },
    async createNewDns() {
      try{
        const response = await createDnsAccount(this.create_dns_form).then(res => res.data);
        if (response && response.message.includes('created successfully')) {
          
          this.dns_accounts = await getDnsAccounts().then(res => res.data);
          this.messageClass = 'bg-green-500'
          this.message = 'Dns created successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't create the dns account"
          setTimeout(() => this.message = '', 2000);
        }
        this.create_dns_form = {
          name: '',
          provider: '',
          api_key: '',
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't create the dns account"
        setTimeout(() => this.message = '', 2000);
      }
    },
    async editDns(id) {
      try{
        const response = await updateDnsAccount(id, this.edit_dns_form).then(res => res.data);
        if (response && response.message.includes('edited successfully')) {
          this.dns_accounts = await getDnsAccounts().then(res => res.data);
          this.messageClass = 'bg-green-500'
          this.message = 'Dns edited successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't edit the dns account"
          setTimeout(() => this.message = '', 2000);
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't edit the dns account"
        setTimeout(() => this.message = '', 2000);
      }
    },
    async delDns(id) {
      try{
        const response = await deleteDnsAccount(id).then(res => res.data);
        if (response && response.message.includes('deleted successfully')) {
          this.edit_dns_form = {
            id: '',
            name: '',
            provider: '',
            api_key: '',
          }
          this.dns_accounts = await getDnsAccounts().then(res => res.data);
          this.messageClass = 'bg-green-500'
          this.message = 'Dns deleted successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't delete the dns account"
          setTimeout(() => this.message = '', 2000);
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't delete the dns account"
        setTimeout(() => this.message = '', 2000);
      }
    },
  },

  computed: {
    // Cloud
    filteredCloudAccount(){
      return this.cloud_accounts.filter(cloud_account => {
        return cloud_account.name.toLowerCase().indexOf(this.cloud_search.toLowerCase()) != -1;
      })
    },

    // Repo
    filteredRepoAccount(){
      return this.repo_accounts.filter(repos_account => {
        return repos_account.name.toLowerCase().indexOf(this.repo_search.toLowerCase()) != -1;
      })
    },

    // Dns
    filteredDnsAccount(){
      return this.dns_accounts.filter(dns_accounts => {
        return dns_accounts.name.toLowerCase().indexOf(this.dns_search.toLowerCase()) != -1;
      })
    }
  }
  
};
</script>
  