<template>
  <ProjectLayout>
    <div class="flex flex-col">
      <div class="flex-row my-4 items-stretch">
        <h1 class="text-3xl font-bold float-start">Overview</h1>
        <button @click="showEditProjectPopup" class="bg-fuchsia-700 hover:bg-fuchsia-800 rounded py-1 px-4 text-white font-medium float-end mx-1 border border-white" type="button">Edit Project</button>
      </div>
      <div v-if="message" class="float-start flex-row rounded m-2" :class="messageClass">
        <p> {{ message }}</p>
      </div>
      <div class="text-left">
        <p>Project: {{ project.name }}</p>
        <p>Deployments: {{ deployments.length }}</p>
      </div>
      <PopupForm ref="editProjectForm" title="Edit Client" @form-submitted="editProject()">
        <div class="mb-4">
          <label for="name" class="block text-sm font-medium text-gray-700 float-start">Name</label>
          <input id="name" v-model="edit_form.name" type="text" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
        <div class="mb-4">
          <div class="flex-col w-full">
            <label for="client" class="block text-sm font-medium text-gray-700 float-start">Client</label>
            <select id="client" v-model="edit_form.client_id" required class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2">
              <option v-for="client in all_clients" :value="client.id" :key="client.id">{{ client.name }}</option>
            </select>
          </div>
        </div>
      </PopupForm>
    </div>

  </ProjectLayout>
</template>
  
<script>
import ProjectLayout from '@/layouts/ProjectLayout'
import PopupForm from '@/components/PopupForm.vue';

import {getClients, getProjectById, updateProject} from '@/services/api';
  
export default {
  components: {
    ProjectLayout,
    PopupForm,
  },

  data() {
    return {
      project: '',
      deployments: '',
      edit_form: {
        name: '',
        client_id: '',
        logo_path: ''
      },
      all_clients: [],
      message: '',
      messageClass: '',
    };
  },
  async mounted(){
    this.project = await getProjectById(this.$route.params.id).then(res => res.data);
    this.deployments = this.project.deployments;
    this.all_clients = await getClients().then(res => res.data);
  },
  methods: {
    showEditProjectPopup() {
      this.edit_form.client_id = this.project.client_id
      this.edit_form.name = this.project.name
      this.edit_form.logo_path = this.project.logo_path
      this.$refs.editProjectForm.openForm();
    },
    async editProject() {
      try{
        const response = await updateProject(this.project.id, this.edit_form).then(res => res.data);
        if (response && response.message.includes('edited successfully')) {
          this.project = await getProjectById(this.$route.params.id).then(res => res.data);
          this.messageClass = 'bg-green-500'
          this.message = 'Client edited successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't edit the client"
          setTimeout(() => this.message = '', 2000);
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't edit the client"
        setTimeout(() => this.message = '', 2000);
      }
    },
  },
};
</script>
  