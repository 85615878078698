<template>
  <GeneralLayout>
    <div class="flex flex-col">
      <div class="flex-row my-4 items-stretch">
        <h1 class="text-3xl font-bold float-start">Plans</h1>
      </div>
      <div v-if="message" class="float-start flex-row rounded m-2" :class="messageClass">
        <p>{{ message }}</p>
      </div>
      <div class="flex-row my-4 items-stretch">
        <button @click="showCloud" class="float-start px-3 py-1 border rounded hover:bg-gray-200" :class="cloud_class">Cloud</button>
        <button @click="showBackup" class="float-start px-3 py-1 ml-1 border rounded hover:bg-gray-200" :class="backup_class">Backup</button>
        <button @click="showMonitoring" class="float-start px-3 py-1 ml-1 border rounded hover:bg-gray-200" :class="monitoring_class">Monitoring</button>

        <div v-if="show_cloud">
          <button @click="showNewCloudPlanPopup" class="bg-fuchsia-700 hover:bg-fuchsia-800 rounded py-1 px-4 text-white font-medium float-end mx-1 border border-white" type="button">New Cloud Plan</button>
          <input v-model="cloud_search" type="text" class="border-solid border-gray-200 border rounded-md shadow-sm px-2 py-1 mx-1 float-end " />
        </div>
        <div v-if="show_backup">
          <button @click="showNewBackupPlanPopup" class="bg-fuchsia-700 hover:bg-fuchsia-800 rounded py-1 px-4 text-white font-medium float-end mx-1 border border-white" type="button">New Backup Plan</button>
          <input v-model="backup_search" type="text" class="border-solid border-gray-200 border rounded-md shadow-sm px-2 py-1 mx-1 float-end " />
        </div>
        <div v-if="show_monitoring">
          <button @click="showNewMonitoringPlanPopup" class="bg-fuchsia-700 hover:bg-fuchsia-800 rounded py-1 px-4 text-white font-medium float-end mx-1 border border-white" type="button">New Monitoring Plan</button>
          <input v-model="monitoring_search" type="text" class="border-solid border-gray-200 border rounded-md shadow-sm px-2 py-1 mx-1 float-end " />
        </div>
      </div>

      <!-- Cloud -->
      <div v-if="show_cloud" class="overflow-x-auto">
        <table class="min-w-full bg-white border border-gray-200">
            <thead>
                <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                    <th class="py-3 px-6 text-center">Name</th>
                    <th class="py-3 px-6 text-center">CPUs</th>
                    <th class="py-3 px-6 text-center">Ram in MB</th>
                    <th class="py-3 px-6 text-center">Disk in GB</th>
                    <th class="py-3 px-6 text-center">Number of Deployments</th>
                    <th class="py-3 px-6 text-center">Actions</th>

                </tr>
            </thead>
            <tbody class="text-gray-600 text-sm font-light min-w-full">
                <tr v-for="cloud_plan in filteredCloudPlan" :key="cloud_plan.id" class="border-b border-gray-200">
                    <td class="py-3 px-6 text-center ">{{cloud_plan.name}}</td>
                    <td class="py-3 px-6 text-center">{{ cloud_plan.cpus }}</td>
                    <td class="py-3 px-6 text-center">{{ cloud_plan.ram_in_mb }}</td>
                    <td class="py-3 px-6 text-center">{{ cloud_plan.disk_in_gb }}</td>
                    <td class="py-3 px-6 text-center">{{ cloud_plan.projects_count }}</td>
                    <td class="py-3 px-6 text-center">
                        <button @click="showEditCloudPlanPopup(cloud_plan)" class="bg-white text-fuchsia-600 py-1 px-3 rounded hover:bg-gray-200 font-medium mr-2 border-solid border border-fuchsia-600">Edit</button>
                        <button @click="showDeleteCloudPlanPopup(cloud_plan.id)" class="bg-fuchsia-700 text-white  py-1 px-3 rounded hover:bg-fuchsia-800 font-medium">Delete</button>
                    </td>
                </tr>
            </tbody>
        </table>
      </div>
      <PopupForm ref="newCloudPlanForm" title="New CloudPlan" @form-submitted="createNewCloudPlan">
        <div class="mb-4">
          <label for="name" class="block text-sm font-medium text-gray-700 float-start">Name</label>
            <input id="name" v-model="create_cloud_form.name" type="text" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
        <div class="mb-4">
          <label for="cpus" class="block text-sm font-medium text-gray-700 float-start">CPUs</label>
          <input id="cpus" v-model="create_cloud_form.cpus" min="1" type="number" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
        <div class="mb-4">
          <label for="ram_in_mb" class="block text-sm font-medium text-gray-700 float-start">RAM in MB</label>
          <input id="ram_in_mb" v-model="create_cloud_form.ram_in_mb" min="1" type="number" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
        <div class="mb-4">
          <label for="disk_in_gb" class="block text-sm font-medium text-gray-700 float-start">Disk in GB</label>
          <input id="disk_in_gb" v-model="create_cloud_form.disk_in_gb" min="1" type="number" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
      </PopupForm>
      <PopupForm ref="editCloudPlanForm" title="Edit CloudPlan" @form-submitted="editCloudPlan(edit_cloud_form.id)">
        <div class="mb-4">
          <label for="name" class="block text-sm font-medium text-gray-700 float-start">Name</label>
            <input id="name" v-model="edit_cloud_form.name" type="text" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
        <div class="mb-4">
          <label for="cpus" class="block text-sm font-medium text-gray-700 float-start">CPUs</label>
          <input id="cpus" v-model="edit_cloud_form.cpus" min="1" type="number" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
        <div class="mb-4">
          <label for="ram_in_mb" class="block text-sm font-medium text-gray-700 float-start">RAM in MB</label>
          <input id="ram_in_mb" v-model="edit_cloud_form.ram_in_mb" min="1" type="number" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
        <div class="mb-4">
          <label for="disk_in_gb" class="block text-sm font-medium text-gray-700 float-start">Disk in GB</label>
          <input id="disk_in_gb" v-model="edit_cloud_form.disk_in_gb" min="1" type="number" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
      </PopupForm>
      <PopupForm ref="deleteCloudPlanForm" title="Delete CloudPlan" @form-submitted="delCloudPlan(cloud_delete_id)">
        <div class="mb-4">
          <p class="block text-sm font-medium text-gray-700 float-start">Are you sure do you want to delete it?</p>
        </div>
      </PopupForm>

      <!-- Backup -->
      <div v-if="show_backup" class="overflow-x-auto">
        <table class="min-w-full bg-white border border-gray-200">
            <thead>
                <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                    <th class="py-3 px-6 text-center">Name</th>
                    <th class="py-3 px-6 text-center">Periodicity</th>
                    <th class="py-3 px-6 text-center">Retention (Days)</th>
                    <th class="py-3 px-6 text-center">Number of Deployments</th>
                    <th class="py-3 px-6 text-center">Actions</th>
                </tr>
            </thead>
            <tbody class="text-gray-600 text-sm font-light min-w-full">
                <tr v-for="backup_plan in filteredBackupPlan" :key="backup_plan.id" class="border-b border-gray-200">
                    <td class="py-3 px-6 text-center ">{{backup_plan.name}}
                    </td>
                    <td class="py-3 px-6 text-center">{{ backup_plan.periodicity }}</td>
                    <td class="py-3 px-6 text-center">{{ backup_plan.retention_days }}</td>
                    <td class="py-3 px-6 text-center">{{ backup_plan.projects_count }}</td>
                    <td class="py-3 px-6 text-center">
                        <button @click="showEditBackupPlanPopup(backup_plan)" class="bg-white text-fuchsia-600 py-1 px-3 rounded hover:bg-gray-200 font-medium mr-2 border-solid border border-fuchsia-600">Edit</button>
                        <button @click="showDeleteBackupPlanPopup(backup_plan.id)" class="bg-fuchsia-700 text-white  py-1 px-3 rounded hover:bg-fuchsia-800 font-medium">Delete</button>
                    </td>
                </tr>
            </tbody>
        </table>
      </div>
      <PopupForm ref="newBackupPlanForm" title="New BackupPlan" @form-submitted="createNewBackupPlan">
        <div class="mb-4">
          <label for="name" class="block text-sm font-medium text-gray-700 float-start">Name</label>
            <input id="name" v-model="create_backup_form.name" type="text" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
        <div class="mb-4">
          <label for="periodicity" class="block text-sm font-medium text-gray-700 float-start">Periodicity</label><br/>
          <select v-model="create_backup_form.periodicity" required class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2">
            <option v-for="option in periodicity_options" :key="option.name" :label="option.name" :value="option.value">{{option.name}}</option>
          </select>
        </div>
        <div class="mb-4">
          <label for="retention_days" class="block text-sm font-medium text-gray-700 float-start">Retention (Days)</label>
          <input id="retention_days" v-model="create_backup_form.retention_days" min="1" type="number" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
      </PopupForm>
      <PopupForm ref="editBackupPlanForm" title="Edit Backup Plan" @form-submitted="editBackupPlan(edit_backup_form.id)">
        <div class="mb-4">
          <label for="name" class="block text-sm font-medium text-gray-700 float-start">Name</label>
            <input id="name" v-model="edit_backup_form.name" type="text" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
        <div class="mb-4">
          <label for="periodicity" class="block text-sm font-medium text-gray-700 float-start">Periodicity</label><br>
          <select v-model="edit_backup_form.periodicity" required class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2">
            <option v-for="option in periodicity_options" :key="option.name" :label="option.name" :value="option.value">{{option.name}}</option>
          </select>
        </div>
        <div class="mb-4">
          <label for="retention_days" class="block text-sm font-medium text-gray-700 float-start">Retention  (Days)</label>
          <input id="retention_days" v-model="edit_backup_form.retention_days" min="1" type="number" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
      </PopupForm>
      <PopupForm ref="deleteBackupPlanForm" title="Delete Backup Plan" @form-submitted="delBackupPlan(backup_delete_id)">
        <div class="mb-4">
          <p class="block text-sm font-medium text-gray-700 float-start">Are you sure do you want to delete it?</p>
        </div>
      </PopupForm>

      <!-- Monitoring -->
      <div v-if="show_monitoring" class="overflow-x-auto">
          <table class="min-w-full bg-white border border-gray-200">
              <!-- Table Head -->
              <thead>
                  <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                      <th class="py-3 px-6 text-center">Name</th>
                      <th class="py-3 px-6 text-center">Alerting</th>
                      <th class="py-3 px-6 text-center">Check URLs</th>
                      <th class="py-3 px-6 text-center">Metrics</th>
                      <th class="py-3 px-6 text-center">Logs</th>
                      <th class="py-3 px-6 text-center">Retention Days</th>
                      <th class="py-3 px-6 text-center">Number of Deployments</th>
                      <th class="py-3 px-6 text-center">Actions</th>
                  </tr>
              </thead>
              <tbody class="text-gray-600 text-sm font-light min-w-full">
                  <tr v-for="monitoring_plan in filteredMonitoringPlan" :key="monitoring_plan.id" class="border-b border-gray-200">
                      <td class="py-3 px-6 text-center ">{{monitoring_plan.name}}
                      </td>
                      <td class="py-3 px-6 text-center">{{ monitoring_plan.alerting }}</td>
                      <td class="py-3 px-6 text-center">{{ monitoring_plan.url_check }}</td>
                      <td class="py-3 px-6 text-center">{{ monitoring_plan.metrics }}</td>
                      <td class="py-3 px-6 text-center">{{ monitoring_plan.logs }}</td>
                      <td class="py-3 px-6 text-center">{{ monitoring_plan.retention_days }}</td>
                      <td class="py-3 px-6 text-center">{{ monitoring_plan.projects_count }}</td>
                      <td class="py-3 px-6 text-center">
                          <button @click="showEditMonitoringPlanPopup(monitoring_plan)" class="bg-white text-fuchsia-600 py-1 px-3 rounded hover:bg-gray-200 font-medium mr-2 border-solid border border-fuchsia-600">Edit</button>
                          <button @click="showDeleteMonitoringPlanPopup(monitoring_plan.id)" class="bg-fuchsia-700 text-white  py-1 px-3 rounded hover:bg-fuchsia-800 font-medium">Delete</button>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
      <PopupForm ref="newMonitoringPlanForm" title="New MonitoringPlan" @form-submitted="createNewMonitoringPlan">
        <div class="mb-4">
          <label for="name" class="block text-sm font-medium text-gray-700 float-start">Name</label>
            <input id="name" v-model="create_monitoring_form.name" type="text" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
        <div class="mb-4">
          <label for="alerting" class="block text-sm font-medium text-gray-700 float-start">Alerting</label>
          <input id="alerting" v-model="create_monitoring_form.alerting" type="checkbox" class="float-end mx-4 appearance-none w-4 h-4 border border-fuchsia-700 rounded-sm bg-white checked:bg-fuchsia-700 checked:border-0" /><br>
        </div>
        <div class="mb-4">
          <label for="url_check" class="block text-sm font-medium text-gray-700 float-start">Url Checks</label>
          <input id="url_check" v-model="create_monitoring_form.url_check" type="checkbox" class="float-end mx-4 appearance-none w-4 h-4 border border-fuchsia-700 rounded-sm bg-white checked:bg-fuchsia-700 checked:border-0" /><br>
        </div>
        <div class="mb-4">
          <label for="metrics" class="block text-sm font-medium text-gray-700 float-start">Metrics</label>
          <input id="metrics" v-model="create_monitoring_form.metrics" type="checkbox" class="float-end mx-4 appearance-none w-4 h-4 border border-fuchsia-700 rounded-sm bg-white checked:bg-fuchsia-700 checked:border-0" /><br>
        </div>
        <div class="mb-4">
          <label for="logs" class="block text-sm font-medium text-gray-700 float-start">Logs</label>
          <input id="logs" v-model="create_monitoring_form.logs" type="checkbox" class="float-end mx-4 appearance-none w-4 h-4 border border-fuchsia-700 rounded-sm bg-white checked:bg-fuchsia-700 checked:border-0" /><br>
        </div>
        <div class="mb-4">
          <label for="retention_days" class="block text-sm font-medium text-gray-700 float-start">Retention (Days)</label>
          <input id="retention_days" v-model="create_monitoring_form.retention_days" type="number" min="1" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
      </PopupForm>
      <PopupForm ref="editMonitoringPlanForm" title="Edit Monitoring Plan" @form-submitted="editMonitoringPlan(edit_monitoring_form.id)">
        <div class="mb-4">
          <label for="name" class="block text-sm font-medium text-gray-700 float-start">Name</label>
            <input id="name" v-model="edit_monitoring_form.name" type="text" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
        <div class="mb-4">
          <label for="alerting" class="block text-sm font-medium text-gray-700 float-start">Alerting</label>
          <input id="alerting" v-model="edit_monitoring_form.alerting" type="checkbox" class="float-end mx-4 appearance-none w-4 h-4 border border-fuchsia-700 rounded-sm bg-white checked:bg-fuchsia-700 checked:border-0" /><br>
        </div>
        <div class="mb-4">
          <label for="url_check" class="block text-sm font-medium text-gray-700 float-start">Url Checks</label>
          <input id="url_check" v-model="edit_monitoring_form.url_check" type="checkbox" class="float-end mx-4 appearance-none w-4 h-4 border border-fuchsia-700 rounded-sm bg-white checked:bg-fuchsia-700 checked:border-0" /><br>
        </div>
        <div class="mb-4">
          <label for="metrics" class="block text-sm font-medium text-gray-700 float-start">Metrics</label>
          <input id="metrics" v-model="edit_monitoring_form.metrics" type="checkbox" class="float-end mx-4 appearance-none w-4 h-4 border border-fuchsia-700 rounded-sm bg-white checked:bg-fuchsia-700 checked:border-0" /><br>
        </div>
        <div class="mb-4">
          <label for="logs" class="block text-sm font-medium text-gray-700 float-start">Logs</label>
          <input id="logs" v-model="edit_monitoring_form.logs" type="checkbox" class="float-end mx-4 appearance-none w-4 h-4 border border-fuchsia-700 rounded-sm bg-white checked:bg-fuchsia-700 checked:border-0" /><br>
        </div>
        <div class="mb-4">
          <label for="retention_days" class="block text-sm font-medium text-gray-700 float-start">Retention (Days)</label>
          <input id="retention_days" v-model="edit_monitoring_form.retention_days" type="number" min="1" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
        </div>
      </PopupForm>
      <PopupForm ref="deleteMonitoringPlanForm" title="Delete MonitoringPlan" @form-submitted="delMonitoringPlan(monitoring_delete_id)">
        <div class="mb-4">
          <p class="block text-sm font-medium text-gray-700 float-start">Are you sure do you want to delete it?</p>
        </div>
      </PopupForm>
    </div>
  </GeneralLayout>
</template>
  
<script>
import GeneralLayout from '@/layouts/GeneralLayout'
import PopupForm from '@/components/PopupForm';
import { 
  // Cloud
  getCloudPlans, createCloudPlan, updateCloudPlan, deleteCloudPlan,
  // Backup
  getBackupPlans, createBackupPlan, updateBackupPlan, deleteBackupPlan, 
  // Monitoring
  getMonitoringPlans, createMonitoringPlan, updateMonitoringPlan, deleteMonitoringPlan,
} from '../services/api';
  

export default {
  components: {
    GeneralLayout,
    PopupForm,
  },

  data() {
    return {
      // Common
      message: '',
      messageClass: '',
      periodicity_options: [
        {
          name: 'Daily',
          value: 'daily'
        },
        {
          name: 'Weekly',
          value: 'weekly'
        },
        {
          name: 'Monthly',
          value: 'monthly'
        },
      ],

      // Cloud
      create_cloud_form: {
        name: '',
        cpus: '',
        ram_in_mb: '',
        disk_in_gb: '',
      },
      edit_cloud_form: {
        id: '',
        name: '',
        cpus: '',
        ram_in_mb: '',
        disk_in_gb: '',
      },
      cloud_plans: [],
      cloud_search: '',
      cloud_delete_id: '',
      show_cloud: true,
      cloud_class: 'bg-fuchsia-700 text-white',
      
      // Backup
      create_backup_form: {
        name: '',
        periodicity: '',
        retention_days: ''
      },
      edit_backup_form: {
        id: '',
        name: '',
        periodicity: '',
        retention_days: ''
      },
      backup_plans: [],
      backup_search: '',
      backup_delete_id: '',
      show_backup: false,
      backup_class: '',

      // Monitoring
      create_monitoring_form: {
        name: '',
        alerting: false,
        url_check: false,
        logs: false,
        metrics: false,
        retention_days: ''
      },
      edit_monitoring_form: {
        name: '',
        alerting: false,
        url_check: false,
        logs: false,
        metrics: false,
        retention_days: ''
      },
      monitoring_plans: [],
      monitoring_search: '',
      monitoring_delete_id: '',
      show_monitoring: false,
      monitoring_class: '',

    };
  },
  async mounted() {
    try {
      this.cloud_plans = await getCloudPlans().then(res => res.data);
      this.backup_plans = await getBackupPlans().then(res => res.data);
      this.monitoring_plans = await getMonitoringPlans().then(res => res.data);

    }
    catch {
      localStorage.removeItem('token');
      this.$router.push('/login');
    }
  },

  methods: {
    // Cloud
    showCloud(){
      this.show_cloud=true
      this.show_backup=false
      this.show_monitoring=false
      this.cloud_class='bg-fuchsia-700 text-white'
      this.backup_class=''
      this.monitoring_class=''
    },
    showNewCloudPlanPopup() {
      this.$refs.newCloudPlanForm.openForm();
    },
    showDeleteCloudPlanPopup(id) {
      this.cloud_delete_id = id
      this.$refs.deleteCloudPlanForm.openForm();
    },
    showEditCloudPlanPopup(cloud_plan) {
      this.edit_cloud_form.id = cloud_plan.id
      this.edit_cloud_form.name = cloud_plan.name
      this.edit_cloud_form.cpus = cloud_plan.cpus
      this.edit_cloud_form.ram_in_mb = cloud_plan.ram_in_mb
      this.edit_cloud_form.disk_in_gb = cloud_plan.disk_in_gb

      this.$refs.editCloudPlanForm.openForm();
    },
    async createNewCloudPlan() {
      try{
        const response = await createCloudPlan(this.create_cloud_form).then(res => res.data);
        if (response && response.message.includes('created successfully')) {
          this.cloud_plans = await getCloudPlans().then(res => res.data);
          this.messageClass = 'bg-green-500'
          this.message = 'CloudPlan created successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't create the cloud_plan"
          setTimeout(() => this.message = '', 2000);
        }
        this.create_cloud_form = {
          name: '',
          cpus: '',
          ram_in_mb: '',
          disk_in_gb: '',
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't create the cloud_plan"
        setTimeout(() => this.message = '', 2000);
      }
    },
    async editCloudPlan(id) {
      try{
        const response = await updateCloudPlan(id, this.edit_cloud_form).then(res => res.data);
        if (response && response.message.includes('edited successfully')) {
          this.cloud_plans = await getCloudPlans().then(res => res.data);
          this.messageClass = 'bg-green-500'
          this.message = 'CloudPlan edited successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't edit the cloud_plan"
          setTimeout(() => this.message = '', 2000);
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't edit the cloud_plan"
        setTimeout(() => this.message = '', 2000);
      }
    },
    async delCloudPlan(id) {
      try{
        const response = await deleteCloudPlan(id).then(res => res.data);
        if (response && response.message.includes('deleted successfully')) {
          this.edit_cloud_form = {
            id: '',
            name: '',
            periodicity: '',
            retention_days: ''
          }
          this.cloud_plans = await getCloudPlans().then(res => res.data);
          this.messageClass = 'bg-green-500'
          this.message = 'CloudPlan deleted successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't delete the cloud plan, make sure no projects use this plan."
          setTimeout(() => this.message = '', 3000);
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't delete the cloud plan, make sure no projects use this plan."
        setTimeout(() => this.message = '', 3000);
      }
    },

    // Backup
    showBackup(){
      this.show_cloud=false
      this.show_backup=true
      this.show_monitoring=false
      this.cloud_class=''
      this.backup_class='bg-fuchsia-700 text-white'
      this.monitoring_class=''
    },
    showNewBackupPlanPopup() {
      this.$refs.newBackupPlanForm.openForm();
    },
    showDeleteBackupPlanPopup(id) {
      this.backup_delete_id = id
      this.$refs.deleteBackupPlanForm.openForm();
    },
    showEditBackupPlanPopup(backup_plan) {
      this.edit_backup_form.id = backup_plan.id
      this.edit_backup_form.name = backup_plan.name
      this.edit_backup_form.periodicity = backup_plan.periodicity
      this.edit_backup_form.retention_days = backup_plan.retention_days
      this.$refs.editBackupPlanForm.openForm();
    },
    async createNewBackupPlan() {
      try{
        const response = await createBackupPlan(this.create_backup_form).then(res => res.data);
        if (response && response.message.includes('created successfully')) {
          this.backup_plans = await getBackupPlans().then(res => res.data);
          this.messageClass = 'bg-green-500'
          this.message = 'BackupPlan created successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't create the backup plan"
          setTimeout(() => this.message = '', 2000);
        }
        this.create_backup_form = {
          name: '',
          periodicity: '',
          retention_days: ''
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't create the backup plan"
        setTimeout(() => this.message = '', 2000);
      }
    },
    async editBackupPlan(id) {
      try{
        const response = await updateBackupPlan(id, this.edit_backup_form).then(res => res.data);
        if (response && response.message.includes('edited successfully')) {
          this.backup_plans = await getBackupPlans().then(res => res.data);
          this.messageClass = 'bg-green-500'
          this.message = 'BackupPlan edited successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't edit the backup_plan"
          setTimeout(() => this.message = '', 2000);
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't edit the backup_plan"
        setTimeout(() => this.message = '', 2000);
      }
    },
    async delBackupPlan(id) {
      try{
        const response = await deleteBackupPlan(id).then(res => res.data);
        if (response && response.message.includes('deleted successfully')) {
          this.edit_backup_form = {
            name: '',
            description: ''
          }
          this.backup_plans = await getBackupPlans().then(res => res.data);
          this.messageClass = 'bg-green-500'
          this.message = 'BackupPlan deleted successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't delete the backup plan, make sure no projects use this plan."
          setTimeout(() => this.message = '', 3000);
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't delete the backup plan, make sure no projects use this plan."
        setTimeout(() => this.message = '', 3000);
      }
    },


    // Monitoring
    showMonitoring(){
      this.show_cloud=false
      this.show_backup=false
      this.show_monitoring=true
      this.cloud_class=''
      this.backup_class=''
      this.monitoring_class='bg-fuchsia-700 text-white'
    },
    showNewMonitoringPlanPopup() {
      this.$refs.newMonitoringPlanForm.openForm();
    },
    showDeleteMonitoringPlanPopup(id) {
      this.monitoring_delete_id = id
      this.$refs.deleteMonitoringPlanForm.openForm();
    },
    showEditMonitoringPlanPopup(monitoring_plan) {
      this.edit_monitoring_form.id = monitoring_plan.id
      this.edit_monitoring_form.name = monitoring_plan.name
      this.edit_monitoring_form.alerting = monitoring_plan.alerting
      this.edit_monitoring_form.url_check = monitoring_plan.url_check
      this.edit_monitoring_form.metrics = monitoring_plan.metrics
      this.edit_monitoring_form.logs = monitoring_plan.logs
      this.edit_monitoring_form.retention_days = monitoring_plan.retention_days
      this.$refs.editMonitoringPlanForm.openForm();
    },
    async createNewMonitoringPlan() {
      try{
        const response = await createMonitoringPlan(this.create_monitoring_form).then(res => res.data);
        if (response && response.message.includes('created successfully')) {
          this.monitoring_plans = await getMonitoringPlans().then(res => res.data);
          this.messageClass = 'bg-green-500'
          this.message = 'Monitoring plan created successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't create the monitoring plan."
          setTimeout(() => this.message = '', 2000);
        }
        this.create_monitoring_form = {
          name: '',
          alerting: false,
          url_check: false,
          logs: false,
          metrics: false,
          retention_days: ''
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't create the monitoring plan."
        setTimeout(() => this.message = '', 2000);
      }
    },
    async editMonitoringPlan(id) {
      try{
        const response = await updateMonitoringPlan(id, this.edit_monitoring_form).then(res => res.data);
        if (response && response.message.includes('edited successfully')) {
          this.monitoring_plans = await getMonitoringPlans().then(res => res.data);
          this.messageClass = 'bg-green-500'
          this.message = 'Monitoring plan edited successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't edit the monitoring plan."
          setTimeout(() => this.message = '', 2000);
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't edit the monitoring plan."
        setTimeout(() => this.message = '', 2000);
      }
    },
    async delMonitoringPlan(id) {
      try{
        const response = await deleteMonitoringPlan(id).then(res => res.data);
        if (response && response.message.includes('deleted successfully')) {
          this.edit_monitoring_form = {
            name: '',
            description: ''
          }
          this.monitoring_plans = await getMonitoringPlans().then(res => res.data);
          this.messageClass = 'bg-green-500'
          this.message = 'MonitoringPlan deleted successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't delete the monitoring plan, make sure no projects use this plan."
          setTimeout(() => this.message = '', 3000);
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't delete the monitoring plan, make sure no projects use this plan."
        setTimeout(() => this.message = '', 3000);
      }
    },
  },
  computed: {
    // Cloud
    filteredCloudPlan(){
      return this.cloud_plans.filter(cloud_plan => {
        return cloud_plan.name.toLowerCase().indexOf(this.cloud_search.toLowerCase()) != -1;
      })
    },

    // Backup
    filteredBackupPlan(){
      return this.backup_plans.filter(backup_plan => {
        return backup_plan.name.toLowerCase().indexOf(this.backup_search.toLowerCase()) != -1;
      })
    },

    // Monitoring
    filteredMonitoringPlan(){
      return this.monitoring_plans.filter(monitoring_plan => {
        return monitoring_plan.name.toLowerCase().indexOf(this.monitoring_search.toLowerCase()) != -1;
      })
    }
  }
  
};
</script>