<template>
  <ProjectLayout>
    <div class="flex flex-col">
      <div class="flex-row my-4 items-stretch">
        <h1 class="text-3xl font-bold float-start">Repositories</h1>
        <button @click="manageRepositoriesPopup" class="bg-fuchsia-700 hover:bg-fuchsia-800 rounded py-1 px-4 text-white font-medium float-end mx-1 border border-white" type="button">Manage Repositories</button>
        <input v-model="search_repository" type="text" class="border-solid border-gray-200 border rounded-md shadow-sm px-2 py-1 mx-1 float-end " />
      </div>
      <div v-if="message" class="float-start flex-row rounded m-2" :class="messageClass">
        <p>{{ message }}</p>
      </div>
      <div class="overflow-x-auto">
        <table class="min-w-full bg-white border border-gray-200">
            <thead>
                <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                    <th class="py-3 px-6 text-center">Name</th>
                    <th class="py-3 px-6 text-center">Owner</th>
                    <th class="py-3 px-6 text-center">Url</th>
                    <th class="py-3 px-6 text-center">Type</th>
                    <th class="py-3 px-6 text-center">Technology</th>
                    <th class="py-3 px-6 text-center">Actions</th>
                </tr>
            </thead>
            <tbody class="text-gray-600 text-sm font-light min-w-full">
                <tr v-for="repository in filteredRepository" :key="repository.id" class="border-b border-gray-200">
                    <td class="py-3 px-6 text-center ">{{repository.name}}</td>
                    <td class="py-3 px-6 text-center ">{{repository.owner}}</td>
                    <td class="py-3 px-6 text-center">{{ repository.url }}</td>
                    <td class="py-3 px-6 text-center">{{ repository.type }}</td>
                    <td class="py-3 px-6 text-center">{{ repository.technology }}</td>
                    <td class="py-3 px-6 text-center">
                        <button @click="showEditRepositoryPopup(repository)" class="bg-white text-fuchsia-600 py-1 px-3 rounded hover:bg-gray-200 font-medium mr-2 border-solid border border-fuchsia-600">Edit</button>
                        <button @click="showDeleteRepositoryPopup(repository.id)" class="bg-fuchsia-700 text-white  py-1 px-3 rounded hover:bg-fuchsia-800 font-medium">Delete</button>
                    </td>
                </tr>
            </tbody>
        </table>
      </div>
      
      <PopupForm ref="manageRepoitoriesForm" title="Manage Repositories" @form-submitted="updateProjectRepositories">
        <div class="max-h-80 overflow-y-auto p-2 mb-4">
          <input v-model="search_manage_repositories" type="text" class="border-solid border-gray-200 border rounded-md shadow-sm px-2 py-1 mx-1" />
          <label v-for="option in filteredManageRepositories" :key="option.id" class="flex items-center space-x-2">
              <input type="checkbox" :value="option.id" v-model="selected_repos.ids" class="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500">
              <span class="text-gray-700">{{ option.owner+'/'+option.name }}</span>
          </label>
        </div>
      </PopupForm>
      <PopupForm ref="editRepositoryForm" title="Edit Repository" @form-submitted="editRepository(edit_repository_form.id)">
        <div class="mb-4">
          <label for="name" class="block text-sm font-medium text-gray-700 float-start">Type</label>
          <select id="provider" v-model="edit_repository_form.type" required class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2">
            <option v-for="provider in repo_types" :key="provider" :label="provider" :value="provider"></option>
          </select>
        </div>
        <div class="mb-4">
          <label for="provider" class="block text-sm font-medium text-gray-700 float-start">Technology</label>
          <select id="provider" v-model="edit_repository_form.technology" required class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2">
            <option v-for="provider in repo_technologies" :key="provider" :label="provider" :value="provider"></option>
          </select>
        </div>
      </PopupForm>
      <PopupForm ref="deleteRepositoryForm" title="Delete Repository" @form-submitted="delRepository(repository_delete_id)">
        <div class="mb-4">
          <p class="block text-sm font-medium text-gray-700 float-start">Are you sure do you want to delete it?</p>
        </div>
      </PopupForm>
    </div>
  </ProjectLayout>
</template>
  
<script>
import ProjectLayout from '@/layouts/ProjectLayout'
import PopupForm from '@/components/PopupForm.vue';
import { getRepositories, getProjectRepositories, updateProjectRepositories, updateRepository, deleteRepository } from '../services/api';
  
export default {
  components: {
    ProjectLayout,
    PopupForm,
  },

  data() {
    return {
      all_repositories: [],
      message: '',
      messageClass: '',
      search_repository: '',
      search_manage_repositories: '',
      project_repositories: [],
      edit_repository_form: {
        id: '',
        name: '',
        owner: '',
        url: '',
        type: '',
        technology: '',
        repository_account_id: ''
      },
      repository_delete_id: '',
      selected_repos: {
        ids: []
      },
      repo_types: ['frontend', 'backend', 'frontend-only', 'backend-only', 'fullstack'],
      repo_technologies: ['fastapi', 'laravel', 'wordpress', 'nuxt']  
    };
  },
  async mounted() {
    try {
      this.project_repositories = await getProjectRepositories(this.$route.params.id).then(res => res.data);
      this.all_repositories = await getRepositories().then(res => res.data);
      this.selected_repos.ids = this.project_repositories.map(repository => repository.id);
    }
    catch {
      localStorage.removeItem('token');
      this.$router.push('/login');
    }
  },

  methods: {
    async manageRepositoriesPopup() {
      this.$refs.manageRepoitoriesForm.openForm();
      this.all_repositories = await getRepositories().then(res => res.data);
      this.selected_repos.ids = this.project_repositories.map(repository => repository.id);
    },
    async updateProjectRepositories() {
      const response = await updateProjectRepositories(this.$route.params.id, this.selected_repos).then(res => res.data);
      if (response){
        this.project_repositories = await getProjectRepositories(this.$route.params.id).then(res => res.data);
        this.messageClass = 'bg-green-500'
        this.message = 'Project Repositories updated successfully'
        setTimeout(() => this.message = '', 2000);
      }
    },

    showEditRepositoryPopup(repository) {
      this.edit_repository_form.id = repository.id
      this.edit_repository_form.name = repository.name
      this.edit_repository_form.owner = repository.owner
      this.edit_repository_form.url = repository.url
      this.edit_repository_form.type = repository.type
      this.edit_repository_form.technology = repository.technology
      this.edit_repository_form.repository_account_id = repository.repository_account_id
      this.$refs.editRepositoryForm.openForm();
    },

    async editRepository(id) {
      try{
        console.log(this.edit_repository_form)
        const response = await updateRepository(id, this.edit_repository_form).then(res => res.data);
        if (response && response.message.includes('edited successfully')) {
          this.project_repositories = await getProjectRepositories(this.$route.params.id).then(res => res.data);
          this.messageClass = 'bg-green-500'
          this.message = 'Repository edited successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't edit the repository"
          setTimeout(() => this.message = '', 2000);
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't edit the repository"
        setTimeout(() => this.message = '', 2000);
      }
    },
    showDeleteRepositoryPopup(id) {
      this.repository_delete_id = id
      this.$refs.deleteRepositoryForm.openForm();
    },
    async delRepository(id) {
      try{
        const response = await deleteRepository(id).then(res => res.data);
        if (response && response.message.includes('deleted successfully')) {
          this.edit_repository_form = {
            id: '',
            name: '',
            owner: '',
            url: '',
            type: '',
            technology: '',
            repository_account_id: '',
          }
          this.project_repositories = await getProjectRepositories(this.$route.params.id).then(res => res.data);
          this.messageClass = 'bg-green-500'
          this.message = 'Repository deleted successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't delete the repository"
          setTimeout(() => this.message = '', 2000);
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't delete the repository"
        setTimeout(() => this.message = '', 2000);
      }
    },

   
  },

  computed: {
    filteredRepository(){
      return this.project_repositories.filter(repository => {
        return repository.name.toLowerCase().indexOf(this.search_repository.toLowerCase()) != -1;
      })
    },
    filteredManageRepositories(){
      return this.all_repositories.filter(repository => {
        return repository.name.toLowerCase().indexOf(this.search_manage_repositories.toLowerCase()) != -1;
      })
    }
  }
  
};
</script>
  