import axios from 'axios';
// require('dotenv').config()

// Base API URL (replace with your actual API URL)
const api = axios.create({
  baseURL: process.env.VUE_APP_BASEURL || 'https://devops-backend.nordkat.com',
  headers: {
    "Content-Type": "application/json"
  }
});

// Attach token to requests
api.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

console.log('BASE URL:', process.env.VUE_APP_BASEURL);

// Authentication APIs
export const login = (data) => api.post('/login', data);
export const changePassword = (data) => api.post('/change-password', data);
export const refreshToken = (data) => api.post('/refresh', data);

// Clients CRUD
export const getClients = () => api.get('/clients');
export const createClient = (data) => api.post('/clients', data);
export const getClientById = (id) => api.get(`/clients/${id}`);
export const updateClient = (id, data) => api.put(`/clients/${id}`, data);
export const deleteClient = (id) => api.delete(`/clients/${id}`);

// Projects CRUD
export const getProjects = () => api.get('/projects');
export const createProject = (data) => api.post('/projects', data);
export const getProjectById = (id) => api.get(`/projects/${id}`);
export const updateProject = (id, data) => api.put(`/projects/${id}`, data);
export const deleteProject = (id) => api.delete(`/projects/${id}`);
export const getProjectRepositories = (id) => api.get(`/projects/${id}/repositories`);
export const updateProjectRepositories = (id, data) => api.post(`/projects/${id}/repositories`, data);
export const getProjectDomains = (id) => api.get(`/projects/${id}/domains`);
export const updateProjectDomains = (id, data) => api.post(`/projects/${id}/domains`, data);

// Deployments CRUD
export const getDeployments = (project_id) => api.get(`/projects/${project_id}/deployments`);
export const createDeployment = (project_id, data) => api.post(`/projects/${project_id}/deployments`, data);
export const getDeploymentById = (project_id, id) => api.get(`/projects/${project_id}/deployments/${id}`);
export const updateDeployment = (id, data) => api.put(`/deployments/${id}`, data);
export const deleteDeployment = (id) => api.delete(`/deployments/${id}`);

// Monitoring Plans CRUD
export const getMonitoringPlans = () => api.get('/monitoring_plans');
export const createMonitoringPlan = (data) => api.post('/monitoring_plans', data);
export const getMonitoringPlanById = (id) => api.get(`/monitoring_plans/${id}`);
export const updateMonitoringPlan = (id, data) => api.put(`/monitoring_plans/${id}`, data);
export const deleteMonitoringPlan = (id) => api.delete(`/monitoring_plans/${id}`);

// Backup Plans CRUD
export const getBackupPlans = () => api.get('/backup_plans');
export const createBackupPlan = (data) => api.post('/backup_plans', data);
export const getBackupPlanById = (id) => api.get(`/backup_plans/${id}`);
export const updateBackupPlan = (id, data) => api.put(`/backup_plans/${id}`, data);
export const deleteBackupPlan = (id) => api.delete(`/backup_plans/${id}`);

// Cloud Plans CRUD
export const getCloudPlans = () => api.get('/cloud_plans');
export const createCloudPlan = (data) => api.post('/cloud_plans', data);
export const getCloudPlanById = (id) => api.get(`/cloud_plans/${id}`);
export const updateCloudPlan = (id, data) => api.put(`/cloud_plans/${id}`, data);
export const deleteCloudPlan = (id) => api.delete(`/cloud_plans/${id}`);

// Cloud Account CRUD
export const getCloudAccounts = () => api.get('/cloud_accounts');
export const createCloudAccount = (data) => api.post('/cloud_accounts', data);
export const getCloudAccountById = (id) => api.get(`/cloud_accounts/${id}`);
export const updateCloudAccount = (id, data) => api.put(`/cloud_accounts/${id}`, data);
export const deleteCloudAccount = (id) => api.delete(`/cloud_accounts/${id}`);

// Repository Account CRUD
export const getRepositoryAccounts = () => api.get('/repository_accounts');
export const createRepositoryAccount = (data) => api.post('/repository_accounts', data);
export const getRepositoryAccountById = (id) => api.get(`/repository_accounts/${id}`);
export const updateRepositoryAccount = (id, data) => api.put(`/repository_accounts/${id}`, data);
export const deleteRepositoryAccount = (id) => api.delete(`/repository_accounts/${id}`);

// Cloud Account CRUD
export const getDnsAccounts = () => api.get('/dns_accounts');
export const createDnsAccount = (data) => api.post('/dns_accounts', data);
export const getDnsAccountById = (id) => api.get(`/dns_accounts/${id}`);
export const updateDnsAccount = (id, data) => api.put(`/dns_accounts/${id}`, data);
export const deleteDnsAccount = (id) => api.delete(`/dns_accounts/${id}`);

// Repositories CRUD
export const getRepositories = () => api.get('/repositories');
export const updateRepository = (id, data) => api.put(`/repositories/${id}`, data);
export const deleteRepository = (id) => api.delete(`/repositories/${id}`);
export const getBranches = (id) => api.get(`/repositories/${id}/branches`);
export const getRepository = (id) => api.get(`/repositories/${id}`);

// Domains CRUD
export const getDomains = () => api.get('/domains');
export const updateDomain = (id, data) => api.put(`/domains/${id}`, data);

// Deployment
export const checkServer = (id) => api.get(`/deployments/${id}/server`);
export const searchServer = (id) => api.post(`/deployments/${id}/server`);
export const setupServer = (id) => api.put(`/setup-server/${id}`);
export const startDeployment = (id) => api.put(`/start-deployment/${id}`);

