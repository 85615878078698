<template>
    <app-layout>
        <div class="fixed top-0 left-0 h-full w-64 bg-gray-800 text-white">
            <div class="p-6">
                <RouterLink :to="'/projects'" class="p-3 rounded hover:bg-gray-600 flex" title="Projects">
                    <span class="my-1 mx-3 bold text-xl">Projects</span>
                </RouterLink>
            </div>
            <nav class="mb-6">
                <ul>
                    <li v-for="section in sections" class="mb-2" :key="section.name">
                        <RouterLink :to="'/project/'+$route.params.id+'/'+section.name" class="p-3 rounded hover:bg-gray-600 flex" :title="section.name" :class="section.class">
                            <img class="rounded h-8 ml-5" :src="'https://ui-avatars.com/api/?background='+section.color+'&color=fff&name='+section.name"  :alt="section.name"/>
                            <span class="my-1 mx-3 capitalize">{{section.name}}</span>
                        </RouterLink>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="ml-64 p-4">
        <div class="container mx-auto px-4">
            <slot></slot>
        </div>
        </div>
    </app-layout>
</template>
<script>
import AppLayout from '@/layouts/AppLayout'
import { getProjectById } from '@/services/api';

export default {
    data() {
        return {
            project_name: '',
            sections: [
                {
                    name: 'overview',
                    color: '16a085',
                    class: ''
                },
                {
                    name: 'deployments',
                    color: 'e74c3c',
                    class: ''
                },
                {
                    name: 'repositories',
                    color: '8e44ad',
                    class: ''
                },
                {
                    name: 'domains',
                    color: '3498db',
                    class: ''
                },
                // {
                //     name: 'cloud',
                //     color: '16a085',
                //     class: ''
                // },
                // {
                //     name: 'backups',
                //     color: 'f1c40f',
                //     class: ''
                // },
                // {
                //     name: 'monitoring',
                //     color: 'd35400',
                //     class: ''
                // },
            ]
        }
    },
    components: {
        AppLayout
    },
    async mounted(){
        try {
            this.project_name = await getProjectById(this.$route.params.id).then(res => res.data.name);
        }
        catch {
            localStorage.removeItem('token');
            this.$router.push('/login');
        }
        
    },
    created(){
        this.sections.forEach( (section, index) => {
            if (this.$route.fullPath == '/project/'+this.$route.params.id+'/'+section.name){
                this.sections[index].class = 'bg-gray-700';
            }
        });
    }
}

</script>