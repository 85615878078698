<template>
    <div class="fixed inset-0 flex items-center justify-center z-50" v-if="isVisible" >
      <div class="fixed inset-0 bg-gray-800 bg-opacity-75" @click="closeForm"></div>
      <div class="bg-white rounded-lg shadow-lg max-w-lg w-full z-10 p-6">
        <div class="flex justify-between items-center mb-4">
          <h3 class="text-lg font-semibold">{{ title }}</h3>
          <button @click="closeForm" class="text-gray-500 hover:text-gray-700">&times;</button>
        </div>
        <slot>
        </slot>
        <div class="flex justify-end">
        <button @click="closeForm" class="bg-gray-300 hover:bg-gray-400 text-gray-700 px-4 py-1 rounded-full mr-2 font-medium">
          Cancel
        </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>

  export default {
    data() {
      return {
        isVisible: false,
        submitted: false,
      };
    },
    methods: {
      openForm() {
        this.isVisible = true;
      },
      closeForm() {
        this.isVisible = false;
      },
    },
    props: ['title'],
  };
  </script>
