<template>
  <ProjectLayout>
    <div class="flex flex-col">
      <div class="flex-row my-4 items-stretch">
        <h1 class="text-3xl font-bold float-start">Deployments</h1>
        <button @click="showNewDeploymentPopup" class="bg-fuchsia-700 hover:bg-fuchsia-800 rounded py-1 px-4 text-white font-medium float-end mx-1 border border-white" type="button">New Deployment</button>
        <input v-model="search" type="text" class="border-solid border-gray-200 border rounded-md shadow-sm px-2 py-1 mx-1 float-end " />
      </div>
      <div v-if="message" class="float-start flex-row rounded m-2" :class="messageClass">
        <p> {{ message }}</p>
      </div>
      <div class="overflow-x-auto">
          <table class="min-w-full bg-white border border-gray-200">
              <!-- Table Head -->
              <thead>
                  <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                      <th class="py-3 px-6 text-center">Full domain</th>
                      <th class="py-3 px-6 text-center">Repository</th>
                      <th class="py-3 px-6 text-center">Branch</th>
                      <th class="py-3 px-6 text-center">Env</th>
                      <th class="py-3 px-6 text-center">Cloud Plan</th>
                      <th class="py-3 px-6 text-center">Backup Plan</th>
                      <th class="py-3 px-6 text-center">Monitoring Plan</th>
                      <th class="py-3 px-6 text-center">Hostname</th>
                      <th class="py-3 px-6 text-center">Actions</th>
                      <th class="py-3 px-6 text-center">Deploy</th>
                  </tr>
              </thead>
              <tbody class="text-gray-600 text-sm font-light min-w-full">
                  <tr v-for="deploy in filteredDeployment" :key="deploy.id" class="border-b border-gray-200">
                      <td v-if="deploy.deploying" class="py-3 px-6 text-center">{{ deploy.subdomain+'.'+deploy.domain.name+' - Deploying' }}</td><td v-else class="py-3 px-6 text-center ">{{ deploy.subdomain+'.'+deploy.domain.name }}</td>
                      <td v-if="deploy.repository" class="py-3 px-6 text-center ">{{ deploy.repository.url }}</td><td v-else class="py-3 px-6 text-center "></td>
                      <td class="py-3 px-6 text-center ">{{ deploy.branch }}</td>
                      <td class="py-3 px-6 text-center ">{{ deploy.env }}</td>
                      <td v-if="deploy.cloud_plan" class="py-3 px-6 text-center ">{{ deploy.cloud_plan.name }}</td><td v-else class="py-3 px-6 text-center "></td>
                      <td v-if="deploy.backup_plan" class="py-3 px-6 text-center ">{{ deploy.backup_plan.name }}</td><td v-else class="py-3 px-6 text-center "></td>
                      <td v-if="deploy.monitoring_plan" class="py-3 px-6 text-center ">{{ deploy.monitoring_plan.name }}</td><td v-else class="py-3 px-6 text-center "></td>
                      <td v-if="deploy.server" class="py-3 px-6 text-center ">{{ deploy.server.hostname }}</td><td v-else class="py-3 px-6 text-center "></td>

                      <td class="py-3 px-6 text-center">
                        <button @click="showEditDeploymentPopup(deploy)" class="bg-white text-fuchsia-600 py-1 px-3 rounded hover:bg-gray-200 font-medium mr-2 border-solid border border-fuchsia-600">Edit</button>
                        <button @click="showDeleteDeploymentPopup(deploy.id)" class="bg-fuchsia-700 text-white  py-1 px-3 rounded hover:bg-fuchsia-800 font-medium">Delete</button>
                      </td>
                      <td class="py-3 px-6 text-center ">
                        <button @click="startDeploy(deploy)" class="bg-[#16a085] text-white py-1 px-3 rounded hover:bg-gray-200 font-bold mr-2 border-solid border border-white">Deploy</button>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
      <PopupForm ref="newDeploymentForm" title="New Deployment" @form-submitted="createNewDeployment">
        <div v-if="messagePopUp" class="flex flex-row rounded mb-4 w-full" :class="messageClass">
            <p> {{ messagePopUp }}</p>
        </div>
        <div class="mb-4">
          <div class="flex flex-row">
            <label for="name" class="block text-sm font-medium text-gray-700 float-start">Domain</label>
          </div>
          <div class="flex flex-row ">
            <div class="flex-col w-full">
              <input id="name" v-model="create_form.subdomain" type="text" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
            </div>
            <div class="flex-col mt-1 mx-1">
              <span>.</span>
            </div>
            <div class="flex-col w-full">
              <select id="technology" v-model="create_form.domain_id" required class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2">
                <option v-for="domain in project_domains" :value="domain.id" :key="domain.id">{{ domain.name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="mb-4">
          <label for="repository" class="block text-sm font-medium text-gray-700 float-start">Repository</label>
          <select id="repository" v-model="create_form.repository_id" @change="getRepository(create_form.repository_id)" required class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2">
            <option v-for="repository in project_repositories" :value="repository.id" :key="repository.id">{{ repository.name }}</option>
          </select>
        </div>
        <div class="mb-4">
          <label for="branch" class="block text-sm font-medium text-gray-700 float-start">Branch</label>
          <select id="branch" v-model="create_form.branch" required class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2">
            <option v-for="branch in repository.branches" :value="branch" :key="branch">{{ branch }}</option>
          </select>
        </div>
        <div v-if="['wordpress', 'fastapi', 'laravel'].includes(repository.technology)" class="mb-4">
          <label for="backup_path" class="block text-sm font-medium text-gray-700 float-start">Backup File</label>
          <input ref="backupInput" id="backup_path" @change="onFileChangeBackup" accept=".gz" type="file" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2"  />
        </div>
        <div v-if="['laravel', 'wordpress'].includes(repository.technology)" class="mb-4">
          <label for="uploads_path" class="block text-sm font-medium text-gray-700 float-start">Uploads File</label>
          <input ref="uploadInput" id="uploads_path" @change="onFileChangeUploads" accept=".zip" type="file" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" />
        </div>
        <div class="mb-4">
          <label for="env" class="block text-sm font-medium text-gray-700 float-start">Env</label>
          <select id="env" v-model="create_form.env" required class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2">
            <option value="dev">Dev</option>
            <option value="prd">Prod</option>
          </select>
        </div>
        <div class="mb-4">
          <label for="cloud_plan" class="block text-sm font-medium text-gray-700 float-start">Cloud Plan</label>
          <select id="cloud_plan" v-model="create_form.cloud_plan_id" required class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2">
            <option v-for="plan in cloud_plans" :value="plan.id" :key="plan.id">{{ plan.name }}</option>
          </select>
        </div>
        <div class="mb-4">
          <label for="cloud_plan" class="block text-sm font-medium text-gray-700 float-start">Backup Plan</label>
          <select id="cloud_plan" v-model="create_form.backup_plan_id" required class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2">
            <option v-for="plan in backup_plans" :value="plan.id" :key="plan.id">{{ plan.name }}</option>
          </select>
        </div>
        <div class="mb-4">
          <label for="cloud_plan" class="block text-sm font-medium text-gray-700 float-start">Monitoring Plan</label>
          <select id="cloud_plan" v-model="create_form.monitoring_plan_id" required class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2">
            <option v-for="plan in monitoring_plans" :value="plan.id" :key="plan.id">{{ plan.name }}</option>
          </select>
        </div>
        <div class="mb-4">
          <label for="dotenv" class="block text-sm font-medium text-gray-700 float-start">Env Variables</label>
          <textarea 
            id="dotenv" 
            v-model="create_form.dotenv_variables" 
            @input="adjustTextareaHeight"
            ref="dotenvTextarea"
            class="mt-1 block w-full rounded-md shadow-sm px-2 overflow-y-auto 
                  font-mono text-sm bg-[#1e1e1e] text-[#f97316]
                  border border-[#3e3e42] focus:border-[#007acc] focus:ring-1 focus:ring-[#007acc]
                  leading-normal" 
            :style="{ 
              minHeight: '100px',
              maxHeight: '50vh',
              resize: 'none',
              tabSize: '2',
              lineHeight: '1.5',
              padding: '0.5rem'
            }"
            spellcheck="false"
          ></textarea>
        </div>

      </PopupForm>
      <PopupForm ref="editDeploymentForm" title="Edit Deployment" @form-submitted="editDeployment(edit_form.id)">
        <div v-if="messagePopUp" class="flex flex-row rounded mb-4 w-full" :class="messageClass">
            <p> {{ messagePopUp }}</p>
        </div>
        <div class="mb-4">
          <div class="flex flex-row">
            <label for="name" class="block text-sm font-medium text-gray-700 float-start">Domain</label>
          </div>
          <div class="flex flex-row ">
            <div class="flex-col w-full">
              <input id="name" v-model="edit_form.subdomain" type="text" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" required />
            </div>
            <div class="flex-col mt-1 mx-1">
              <span>.</span>
            </div>
            <div class="flex-col w-full">
              <select id="technology" v-model="edit_form.domain_id" required class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2">
                <option v-for="domain in project_domains" :value="domain.id" :key="domain.id">{{ domain.name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="mb-4">
          <label for="repository" class="block text-sm font-medium text-gray-700 float-start">Repository</label>
          <select id="repository" v-model="edit_form.repository_id" @change="getRepository(edit_form.repository_id)" required class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2">
            <option v-for="repository in project_repositories" :value="repository.id" :key="repository.id">{{ repository.name }}</option>
          </select>
        </div>
        <div class="mb-4">
          <label for="branch" class="block text-sm font-medium text-gray-700 float-start">Branch</label>
          <select id="branch" v-model="edit_form.branch" required class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2">
            <option v-for="branch in repository.branches" :value="branch" :key="branch">{{ branch }}</option>
          </select>
        </div>
        <div>{{ repository.technology }}</div>
        <div v-if="['wordpress', 'fastapi', 'laravel'].includes(repository.technology)" class="mb-4">
          <label for="backup_path" class="block text-sm font-medium text-gray-700 float-start">Backup File</label>
          <input ref="backupInput" id="backup_path" @change="onFileChangeBackup" accept=".gz" type="file" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2"  />
        </div>
        <div v-if="['wordpress', 'laravel'].includes(repository.technology)" class="mb-4">
          <label for="uploads_path" class="block text-sm font-medium text-gray-700 float-start">Uploads File</label>
          <input ref="uploadInput" id="uploads_path" @change="onFileChangeUploads" accept=".zip" type="file" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2" />
        </div>
        <div class="mb-4">
          <label for="env" class="block text-sm font-medium text-gray-700 float-start">Env</label>
          <select id="env" v-model="edit_form.env" required class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2">
            <option value="dev">Dev</option>
            <option value="prd">Prod</option>
          </select>
        </div>
        <div class="mb-4">
          <label for="cloud_plan" class="block text-sm font-medium text-gray-700 float-start">Cloud Plan</label>
          <select id="cloud_plan" v-model="edit_form.cloud_plan_id" required class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2">
            <option v-for="plan in cloud_plans" :value="plan.id" :key="plan.id">{{ plan.name }}</option>
          </select>
        </div>
        <div class="mb-4">
          <label for="cloud_plan" class="block text-sm font-medium text-gray-700 float-start">Backup Plan</label>
          <select id="cloud_plan" v-model="edit_form.backup_plan_id" required class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2">
            <option v-for="plan in backup_plans" :value="plan.id" :key="plan.id">{{ plan.name }}</option>
          </select>
        </div>
        <div class="mb-4">
          <label for="cloud_plan" class="block text-sm font-medium text-gray-700 float-start">Monitoring Plan</label>
          <select id="cloud_plan" v-model="edit_form.monitoring_plan_id" required class="mt-1 block w-full border-gray-300 rounded-md shadow-sm px-2">
            <option v-for="plan in monitoring_plans" :value="plan.id" :key="plan.id">{{ plan.name }}</option>
          </select>
        </div>
        <div class="mb-4">
          <label for="dotenv" class="block text-sm font-medium text-gray-700 float-start">Env Variables</label>
          <textarea 
            id="dotenv" 
            v-model="edit_form.dotenv_variables" 
            @input="adjustTextareaHeight"
            ref="dotenvTextarea"
            class="mt-1 block w-full rounded-md shadow-sm px-2 overflow-y-auto 
                  font-mono text-sm bg-[#1e1e1e] text-[#f97316]
                  border border-[#3e3e42] focus:border-[#007acc] focus:ring-1 focus:ring-[#007acc]
                  leading-normal" 
            :style="{ 
              minHeight: '100px',
              maxHeight: '50vh',
              resize: 'none',
              tabSize: '2',
              lineHeight: '1.5',
              padding: '0.5rem'
            }"
            spellcheck="false"
          ></textarea>
        </div>
      </PopupForm>
      <PopupForm ref="deleteDeploymentForm" title="Delete Deployment" @form-submitted="delDeployment(delete_id)">
        <div class="mb-4">
          <p class="block text-sm font-medium text-gray-700 float-start">Are you sure do you want to delete it?</p>
        </div>
      </PopupForm>
      <PopupInfo ref="deployment" title="Deployment">
        <div class="flex flex-row items-center my-3">
          <span>Deployment Status: {{ deployment_status }} </span>
          <span class="ml-2 loader" v-if="deployment_status == 'Deploying'"></span>
        </div>
        <div class="flex flex-col items-start font-mono text-sm bg-[#1e1e1e] leading-normal mb-4">
          <div v-for="log in deployment_logs" v-bind:key=log.message class="mb-1" :class="log.class">{{ log.message }}</div>
        </div>
      </PopupInfo>
      <PopupInfo ref="loader">
        <div class="flex flex-row items-center my-3">
          <span class="ml-2 loader" v-if="loader"></span>
        </div>
      </PopupInfo>
    </div>
  </ProjectLayout>
</template>
<style>
.loader {
  width: 60px;
  height: 10px;
  border-radius: 20px;
  background:
   repeating-linear-gradient(135deg,#f03355 0 10px,#ffa516 0 20px) 0/0%   no-repeat,
   repeating-linear-gradient(135deg,#ddd    0 10px,#eee    0 20px) 0/100%;
  animation: l3 2s infinite;
}
@keyframes l3 {
    100% {background-size:100%}
}
</style>

<script>
import ProjectLayout from '@/layouts/ProjectLayout'
import PopupForm from '@/components/PopupForm.vue';
import PopupInfo from '@/components/PopupInfo.vue';

import { getDeployments, updateDeployment, createDeployment, deleteDeployment, getProjectDomains, getProjectRepositories, getRepository, searchServer, setupServer, startDeployment, checkServer, getCloudPlans, getBackupPlans, getMonitoringPlans } from '../services/api';
    
export default {
  components: {
    ProjectLayout,
    PopupForm,
    PopupInfo
  },

  data() {
    return {
      create_form: {
        subdomain: '',
        branch: '',
        repository_id: '',
        env: '',
        domain_id: '',
        dotenv_variables: '',
        cloud_plan_id: 1,
        backup_plan_id: 1,
        monitoring_plan_id: 1,
      },
      edit_form: {
        id: '',
        subdomain: '',
        branch: '',
        repository_id: '',
        env: '',
        domain_id: '',
        dotenv_variables: '',
        cloud_plan_id: 1,
        backup_plan_id: 1,
        monitoring_plan_id: 1,
      },
      repository: {},
      deployments: [],
      cloud_plans: [],
      monitoring_plans: [],
      backup_plans: [],
      message: '',
      messageClass: '',
      search: '',
      delete_id: '',
      project_domains: [],
      project_repositories: [],
      backup_file: null,
      uploads_file: null,
      deployment_logs: [],
      deployment_status: '',
      messagePopUp: '',
      loader: false
    };
  },
  async mounted() {
    try {
      this.deployments = await getDeployments(this.$route.params.id).then(res => res.data);
      console.log(this.deployments)
      this.project_domains = await getProjectDomains(this.$route.params.id).then(res => res.data);  
      this.project_repositories = await getProjectRepositories(this.$route.params.id).then(res => res.data);
      if (this.project_domains.length){
        this.create_form.domain_id = this.project_domains[0].id
      }
      if (this.project_repositories.length){
        this.create_form.repository_id = this.project_repositories[0].id
      } 
      this.cloud_plans = await getCloudPlans().then(res => res.data);
      this.backup_plans = await getBackupPlans().then(res => res.data);
      this.monitoring_plans = await getMonitoringPlans().then(res => res.data);

    }
    catch (error) {
      console.log(error)
      localStorage.removeItem('token');
      this.$router.push('/login');
    }
  },

  methods: {
    adjustTextareaHeight(e) {
      const textarea = e.target;
      textarea.style.height = 'auto';  // Reset height
      textarea.style.height = textarea.scrollHeight + 'px';  // Set new height
    },
    onFileChangeBackup(event) {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        if (selectedFile.name.endsWith(".gz")) {
          this.backup_file = selectedFile;
          this.errorMessage = "";
          console.log('selected file')
        } else {
          this.backup_file = null;
          this.messagePopUp = "Only .gz files are allowed.";
          this.messageClass = 'bg-red-500'
          this.$refs.backupInput.value = "";
          setTimeout(() => this.messagePopUp = '', 2000);
        }
      }
    },
    onFileChangeUploads(event) {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        if (selectedFile.name.endsWith(".zip")) {
          this.uploads_file = selectedFile;
          this.errorMessage = "";
        } else {
          this.uploads_file = null;
          this.messagePopUp = "Only .zip files are allowed.";
          this.messageClass = 'bg-red-500'
          this.$refs.uploadInput.value = "";
          setTimeout(() => this.messagePopUp = '', 2000);
        }
      }
    },
    
    showNewDeploymentPopup() {
      this.$refs.newDeploymentForm.openForm();
      this.getRepository(this.create_form.repository_id)
    },
    showDeleteDeploymentPopup(id) {
      this.delete_id = id
      this.$refs.deleteDeploymentForm.openForm();
    },
    showEditDeploymentPopup(deployment) {
      this.edit_form.id = deployment.id
      this.edit_form.subdomain = deployment.subdomain
      this.edit_form.branch = deployment.branch
      this.edit_form.repository_id = deployment.repository_id
      this.edit_form.env = deployment.env
      this.edit_form.domain_id = deployment.domain_id
      this.edit_form.dotenv_variables = deployment.dotenv_variables
      this.edit_form.cloud_plan_id = deployment.cloud_plan_id
      this.edit_form.backup_plan_id = deployment.backup_plan_id
      this.edit_form.monitoring_plan_id = deployment.monitoring_plan_id
      this.backup_file = null
      this.uploads_file = null
      this.$refs.editDeploymentForm.openForm();

      this.getRepository(this.edit_form.repository_id)
    },

    async uploadFile(file, deployment_id) {
      console.log('uploadFile');
      if (!file) {
        alert("Please select a file to upload.");
        return;
      }

      const data = new FormData();
      data.append("file", file);

      try {
        const url = process.env.VUE_APP_BASEURL || 'https://devops-backend.nordkat.com'
        const response = await fetch(url+"/deployments/"+deployment_id+"/file", {
          method: "POST",
          body: data,
          headers: {
            "Authorization": "Bearer "+localStorage.getItem('token'),
          },
        }).then(res => res.data);
        if (response && response.message === 'File uploaded successfully.') {
          console.log("File uploaded successfully!");
        }
        else{
          console.error("Error uploading file:", response.message);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    },
    
    async createNewDeployment() {
      console.log('newDeployment');
      try{
        this.messageClass = 'bg-green-500'
        this.message = "Starting to create the deployment..."
        const response = await createDeployment(this.$route.params.id, this.create_form).then(res => res.data);
        if (response && response.message === 'deployments created successfully') {
          this.deployments = await getDeployments(this.$route.params.id).then(res => res.data);
          const deployment_id = response.id
          if (this.backup_file) {
            try{
              await this.uploadFile(this.backup_file, deployment_id)
              this.messageClass = 'bg-green-500'
              this.message = "The backup has been successfully uploaded."
            }
            catch(err){
              this.messageClass = 'bg-red-500'
              this.message = "Something weird happend, we couldn't upload the file"
              setTimeout(() => this.message = '', 2000);
              return 1
            }
          }
          
          if (this.uploads_file){
            try{
              await this.uploadFile(this.uploads_file, deployment_id)
              this.messageClass = 'bg-green-500'
              this.message = "The uploads file has been successfully uploaded."
            }
            catch(err){
              this.messageClass = 'bg-red-500'
              this.message = "Something weird happend, we couldn't upload the file"
              setTimeout(() => this.message = '', 2000);
              return 1
            }
          }

          this.messageClass = 'bg-green-500'
          this.message = 'Deployment created successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't create the deployment"
          setTimeout(() => this.message = '', 2000);
        }
        this.create_form = {
          subdomain: '',
          branch: '',
          repository_id: '',
          env: '',
          domain_id: '',
          dotenv_variables: '',
          cloud_plan_id: 1,
          backup_plan_id: 1,
          monitoring_plan_id: 1,
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't create the deployment"
        setTimeout(() => this.message = '', 2000);
      }
    },
    async editDeployment(id) {
      try{
        const response = await updateDeployment(id, this.edit_form).then(res => res.data);
        if (response && response.message.includes('edited successfully')) {
          if (this.backup_file) {
            try{
              await this.uploadFile(this.backup_file, id)
              this.messageClass = 'bg-green-500'
              this.message = "The backup has been successfully uploaded."
            }
            catch(err){
              this.messageClass = 'bg-red-500'
              this.message = "Something weird happend, we couldn't upload the file"
              setTimeout(() => this.message = '', 2000);
              return 1
            }
          }
          
          if (this.uploads_file){
            try{
              await this.uploadFile(this.uploads_file, id)
              this.messageClass = 'bg-green-500'
              this.message = "The uploads file has been successfully uploaded."
            }
            catch(err){
              this.messageClass = 'bg-red-500'
              this.message = "Something weird happend, we couldn't upload the file"
              setTimeout(() => this.message = '', 2000);
              return 1
            }
          }
          this.deployments = await getDeployments(this.$route.params.id).then(res => res.data);
          this.messageClass = 'bg-green-500'
          this.message = 'Deployment edited successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't edit the deployment"
          setTimeout(() => this.message = '', 2000);
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't edit the deployment"
        setTimeout(() => this.message = '', 2000);
      }
    },

    async delDeployment(id) {
      this.loader = true
      this.$refs.loader.openForm();
      try{
        const response = await deleteDeployment(id).then(res => res.data);
        if (response && response.message.includes('deleted successfully')) {
          this.edit_form = {
            id: '',
            subdomain: '',
            branch: '',
            repository_id: '',
            env: '',
            domain_id: '',
            dotenv_variables: '',
            cloud_plan_id: 1,
            backup_plan_id: 1,
            monitoring_plan_id: 1,
          },
          this.deployments = await getDeployments(this.$route.params.id).then(res => res.data);
          this.messageClass = 'bg-green-500'
          this.message = 'Deployment deleted successfully'
          setTimeout(() => this.message = '', 2000);
        } 
        else {
          this.messageClass = 'bg-red-500'
          this.message = "Something weird happend, we couldn't delete the deployment"
          setTimeout(() => this.message = '', 2000);
        }
      }
      catch(err){
        this.messageClass = 'bg-red-500'
        this.message = "Something weird happend, we couldn't delete the deployment"
        setTimeout(() => this.message = '', 2000);
      }
      this.loader = false
      this.$refs.loader.closeForm();

    },
    async getRepository(repository_id) {
      this.repository = await getRepository(repository_id).then(res => res.data);
    },
    async startDeploy(deploy){
      this.deployment_logs = []
      deploy.deploying = true
      this.deployment_status = 'Deploying'
      this.$refs.deployment.openForm();
      this.deployment_logs.push({message:'Deployment starting...', class:'text-white'})

      if (!deploy.server_id){
        this.deployment_logs.push({message:'Searching for an available server...', class:'text-white'})
        const response = await searchServer(deploy.id).then(res => res.data);
        console.log(response)
        for (const message of response.messages) {
          this.deployment_logs.push({message: message, class:'text-white'})
        }

        if (!response.nomad_installed){
          this.deployment_logs.push({message:'Setting up client...', class:'text-white'})
          const response2 = await setupServer(response.server_id).then(res => res.data);
          for (const message of response2.messages) {
            this.deployment_logs.push({message: message, class:'text-white'})
          }
        }
      }
      else{
        const server = await checkServer(deploy.server_id).then(res => res.data);
        if (server.message == 'Nomad not reachable'){
          this.deployment_logs.push({message:'Nomad not reachable...', class:'text-white'})
          this.deployment_logs.push({message:'Setting up client...', class:'text-white'})
          const response = await setupServer(server.server_id).then(res => res.data);
          for (const message of response.messages) {
            this.deployment_logs.push({message: message, class:'text-white'})
          }
        }
        if (server.message == 'not reachable'){
          this.deployment_logs.push({message:'Old server not reachable...', class:'text-white'})
          this.deployment_logs.push({message:'Searching for an available server...', class:'text-white'})
          const response = await searchServer(deploy.id).then(res => res.data);
          console.log(response)
          for (const message of response.messages) {
            this.deployment_logs.push({message: message, class:'text-white'})
          }
          if (!response.nomad_installed){
            this.deployment_logs.push({message:'Setting up client...', class:'text-white'})
            const response2 = await setupServer(response.server_id).then(res => res.data);
            for (const message of response2.messages) {
              this.deployment_logs.push({message: message, class:'text-white'})
            }
          }
        }
      }

      this.deployment_logs.push({message:'Starting the deployment process...', class:'text-white'})
      const response3 = await startDeployment(deploy.id).then(res => res.data);
      console.log(response3)
      for (const message of response3.messages) {
        this.deployment_logs.push({message: message, class:'text-white'})
      }
      deploy.deploying = false
      this.deployment_status = 'Finished'
    }
  },
  computed: { 
    filteredDeployment(){
      return this.deployments.filter(deployment => {
        return deployment.subdomain.toLowerCase().indexOf(this.search.toLowerCase()) != -1;
      })
    }
  }
  
};
</script>
  