import { createRouter, createWebHistory } from 'vue-router';
import Login from '../views/LoginView.vue';
import Projects from '../views/ProjectsView.vue';
import Clients from '../views/ClientsView.vue';
import Plans from '../views/PlansView.vue';
import Accounts from '../views/AccountsView.vue';
import Analytics from '../views/AnalyticsView.vue';
import Overview from '../views/OverviewView.vue';
import Deployments from '../views/DeploymentsView.vue';
import Repositories from '../views/RepositoriesView.vue';
import Domains from '../views/DomainsView.vue';
import Cloud from '../views/CloudView.vue';
import Backups from '../views/BackupsView.vue';
import Monitoring from '../views/MonitoringView.vue';




const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', name: 'Login', component: Login },
  { path: '/projects', name: 'Projects', component: Projects },
  { path: '/clients', name: 'Clients', component: Clients },
  { path: '/plans', name: 'Plans', component: Plans },
  { path: '/accounts', name: 'Accounts', component: Accounts },
  { path: '/analytics', name: 'Analytics', component: Analytics },
  { path: '/project/:id/overview', name: 'Overview', component: Overview },
  { path: '/project/:id/deployments', name: 'Deployments', component: Deployments },
  { path: '/project/:id/repositories', name: 'Repositories', component: Repositories },
  { path: '/project/:id/domains', name: 'Domains', component: Domains },
  { path: '/project/:id/cloud', name: 'Cloud', component: Cloud },
  { path: '/project/:id/backups', name: 'Backups', component: Backups },
  { path: '/project/:id/monitoring', name: 'Monitoring', component: Monitoring },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
