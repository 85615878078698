<template>
    <app-layout>
        <div class="fixed top-0 left-0 h-full w-64 bg-gray-800 text-white">
            <div class="p-6">
            </div>
            <nav class="mt-6">
                <ul>
                    <li v-for="section in sections" :key="section.name" class="mb-2">
                        <RouterLink :to="section.url" class="block p-3 rounded hover:bg-gray-600" :class="section.class">{{section.name}}</RouterLink>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="ml-64 p-4">
        <div class="container mx-auto px-4">
            <slot></slot>
        </div>
        </div>
    </app-layout>
</template>
<script>
import AppLayout from '@/layouts/AppLayout'

export default {
    data() {
        return {
            sections:[
                {
                    name: 'Projects',
                    url: '/projects',
                    class: ''
                },
                {
                    name: 'Clients',
                    url: '/clients',
                    class: ''
                },
                {
                    name: 'Plans',
                    url: '/plans',
                    class: ''
                },
                {
                    name: 'Accounts',
                    url: '/accounts',
                    class: ''
                },
                // {
                //     name: 'Analytics',
                //     url: '/analytics',
                //     class: ''
                // }
            ]
        }
    },
    components: {
        AppLayout
    },
    created(){
        this.sections.forEach( (section, index) => {
            if (this.$route.fullPath == section.url){
                this.sections[index].class = 'bg-gray-700';
            }
        });
    }
}

</script>