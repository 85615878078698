<template>
  <ProjectLayout>
    <div class="flex flex-col">
      <div class="flex-row my-4 items-stretch">
        <h1 class="text-3xl font-bold float-start">Domains</h1>
        <button @click="manageDomainsPopup" class="bg-fuchsia-700 hover:bg-fuchsia-800 rounded py-1 px-4 text-white font-medium float-end mx-1 border border-white" type="button">Manage Domains</button>
        <input v-model="search_domain" type="text" class="border-solid border-gray-200 border rounded-md shadow-sm px-2 py-1 mx-1 float-end " />
      </div>
      <div v-if="message" class="float-start flex-row rounded m-2" :class="messageClass">
        <p> {{ message }}</p>
      </div>
      <!-- Table -->
      <div class="overflow-x-auto">
          <table class="min-w-full bg-white border border-gray-200">
              <!-- Table Head -->
              <thead>
                  <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                      <th class="py-3 px-6 text-center">Name</th>
                  </tr>
              </thead>
              <tbody class="text-gray-600 text-sm font-light min-w-full">
                  <tr v-for="domain in filteredDomain" :key="domain.id" class="border-b border-gray-200">
                      <td class="py-3 px-6 text-center ">{{domain.name}}
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
      <PopupForm ref="manageDomainsForm" title="Manage Domains" @form-submitted="updateProjectDomains">
        <div class="mb-4">
          <label v-for="option in all_domains" :key="option.id" class="flex items-center space-x-2">
              <input type="checkbox" :value="option.id" v-model="selected_domains.ids" class="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500">
              <span class="text-gray-700">{{ option.name }}</span>
          </label>
        </div>
      </PopupForm>
    </div>
  </ProjectLayout>
</template>
  
<script>
import ProjectLayout from '@/layouts/ProjectLayout'
import PopupForm from '@/components/PopupForm.vue';
import { getDomains, getProjectDomains, updateProjectDomains } from '../services/api';
  
export default {
  components: {
    ProjectLayout,
    PopupForm,
  },

  data() {
    return {
      all_domains: [],
      message: '',
      messageClass: '',
      search_domain: '',
      project_domains: [],
      selected_domains: {
        ids: []
      },
    };
  },
  async mounted() {
    try {
      this.project_domains = await getProjectDomains(this.$route.params.id).then(res => res.data);
      this.all_domains = await getDomains().then(res => res.data);
      this.selected_domains.ids = this.project_domains.map(domain => domain.id);
    }
    catch {
      localStorage.removeItem('token');
      this.$router.push('/login');
    }
  },

  methods: {
    async manageDomainsPopup() {
      this.$refs.manageDomainsForm.openForm();
      this.all_domains = await getDomains().then(res => res.data);
      this.selected_domains.ids = this.project_domains.map(domain => domain.id);
    },
    async updateProjectDomains() {
      const response = await updateProjectDomains(this.$route.params.id, this.selected_domains).then(res => res.data);
      if (response){
        this.project_domains = await getProjectDomains(this.$route.params.id).then(res => res.data);
        this.messageClass = 'bg-green-500'
        this.message = 'Project Domains updated successfully'
        setTimeout(() => this.message = '', 2000);
      }
    },
  },

  computed: {
    filteredDomain(){
      return this.project_domains.filter(domain => {
        return domain.name.toLowerCase().indexOf(this.search_domain.toLowerCase()) != -1;
      })
    }
  }
  
};
</script>
  